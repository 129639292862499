import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Axis,
  Grid,
  LineSeries,
  XYChart,
  buildChartTheme,
} from "@visx/xychart";
import ChartLegend from "./legend";

const ChartContainer = styled.div``;

const accessors = {
  xAccessor: (d) => d.x,
  yAccessor: (d) => d.y,
};

const SFLineChart = ({
  children,
  data,
  withLegend,
  chartProps,
  xAxisProps,
  yAxisProps,
  yAxisMaxVal,
  unit,
  YAxisLabel,
}) => {
  const [activeSeries, setActiveSeries] = useState({});

  const customTheme = buildChartTheme({
    colors: data
      .filter((series) => activeSeries[series.name])
      .map((series) => series.colour),
  });

  useEffect(() => {
    const newSeries = data.reduce(
      (out, series) => ({
        ...out,
        [series.name]:
          activeSeries[series.name] !== undefined
            ? activeSeries[series.name]
            : true,
      }),
      {}
    );
    setActiveSeries(newSeries);
  }, [data]);
  return (
    <ChartContainer>
      <XYChart
        height={250}
        xScale={{ type: "band" }}
        yScale={{ type: "linear", domain: [0, yAxisMaxVal] }}
        theme={customTheme}
        {...chartProps}
      >
        <Axis orientation="bottom" {...xAxisProps} />
        <Axis
          orientation="left"
          label={YAxisLabel}
          tickFormat={(text) => text + unit}
          tickValues={[
            0,
            yAxisMaxVal / 5,
            (yAxisMaxVal / 5) * 2,
            (yAxisMaxVal / 5) * 3,
            (yAxisMaxVal / 5) * 4,
            yAxisMaxVal,
          ]}
          {...yAxisProps}
        />
        <Grid columns={false} numTicks={4} />
        {children}
        {data
          .filter((series) => activeSeries[series.name])
          .map((series) => (
            <LineSeries
              key={series.name}
              dataKey={series.name}
              data={series.data}
              {...accessors}
            />
          ))}
      </XYChart>
      {withLegend && (
        <ChartLegend
          data={data}
          activeSeries={activeSeries}
          setSeriesActiveState={(seriesName, isActive) =>
            setActiveSeries({
              ...activeSeries,
              [seriesName]: isActive,
            })
          }
        />
      )}
    </ChartContainer>
  );
};

export default SFLineChart;
