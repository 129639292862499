export function getMonday(d) {
  d = new Date(d);
  const day = d.getDay();
  const diff = d.getDate() - day + (day === 0 ? -6 : 1);
  const out = new Date(d);
  out.setTime(d.getTime() - diff * 24 * 60 * 60 * 1000);
  return out;
}

export function getMonthStart() {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getLast24(d) {
  return new Date(d.setTime(d.getTime() - 24 * 60 * 60 * 1000));
}

export const days = "Sunday,Monday,Tuesday,Wednesday,Thursday,Friday,Saturday".split(",");

export const zeroPrefix = n => {
  const s = n + "";
  if (s.length === 1) {
    return `0${n}`;
  } else {
    return n;
  }
}
