import { useState, useEffect, useMemo } from 'react';

const usePromise = (getPromise, deps) => {
  const [isLoading, setLoading] = useState(true);
  const [state, setState] = useState(null);

  const promise = useMemo(getPromise, deps);

  useEffect(() => {
    setLoading(true);
    promise
      .then(setState)
      .then(() => setLoading(false));
  }, [promise])

  return [isLoading, state];
};

export default usePromise;