import React, { useState } from "react";
import { Form, Field } from "formik";
import { withStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import MovieOutlinedIcon from "@material-ui/icons/MovieOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

import styled from "styled-components";

import { MainWhiteContentContainer, FlexRowDiv } from "../../GlobalStyles/GlobalStyles";
import Button from "../Button";
import Categories from "./Categories";

const FlexRowDivMargBotAndLeft = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-left: 25px;
  `;

const ErrorText = styled.p`
    color: red;
  `;

const GreenSwitch = withStyles({
  switchBase: {
    color: "#6DE4B9",
    "&$checked": {
      color: "#6DE4B9",
    },
    "&$checked + $track": {
      backgroundColor: "#6DE4B9",
    },
  },
  checked: {},
  track: {},
})(Switch);

const AddNewExcerciseForm = ({
  disabled,
  title,
  description,
  categories,
  tags,
  isPublic,
  updateValues,
  ...props
}) => {
  const [category, setCategory] = useState(props.values?.category);

  const StyledLabel = styled.label`
    min-width: 250px;
    input[type="radio"] {
      display: none;
    }
    padding-right: 15px;
  `;

  const StyledBottomButton = styled(Button)`
    background-color: #a5a3a3;
    margin-top: 100px;
  `;

  const [publicSwitch, setPublicSwitch] = useState(true);

  const handleSwitch = () => {
    props.values.isPublic = !publicSwitch;
    setPublicSwitch(!publicSwitch);
  };

  const changeCat = (value) => {
    setCategory(value);
  };

  const OptionDiv = styled.div`
    margin-top: 50px;
  `;

  const BoxLabel = styled.label`
    border: 1px solid #c4c4c4;
    margin: 10px 10px 0px 0px;
    padding: 0px 5px;
    border-radius: 5px;
    display: inline-block;
  `;

  return (
    <Form>
      <div>
        <TextField
          id="title"
          name="title"
          label="Exercise Title*"
          disabled={disabled}
          value={props.values.title}
          onChange={props.handleChange("title")}
          onBlur={props.handleChange("title")}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ marginTop: 10, marginRight: 100, width: 300 }}
        />
        {/* This is ugly and shouldn't be here ... but I couldn't get it working and we had a playback */}
        <TextField
          id="isPublic"
          name="isPublic"
          value={props.values.isPublic}
          onChange={props.handleChange("isPublic")}
          onBlur={props.handleChange("isPublic")}
          style={{ display: "none" }}
        />
        <FlexRowDiv>
          <TextField
            id="description"
            name="description"
            label="Exercise Description (for other Therapists)*"
            disabled={disabled}
            value={props.values.description}
            onChange={props.handleChange("description")}
            onBlur={props.handleChange("description")}
            multiline
            rows={4}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ marginTop: 10, marginRight: 100, minWidth: 300 }}
          />
          {/* Comment out as its not wired up */}
          {/* <div
            style={{
              minWidth: "100px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p>Public Exercise</p>
            <GreenSwitch onClick={() => handleSwitch()} />
          </div> */}
        </FlexRowDiv>

        <OptionDiv>
          <div id="categories">Categories*</div>
          <div role="group" aria-labelledby="categories">
            {Categories.map((cat) => (
              <StyledLabel>
                {cat.name === category ? (
                  <CheckCircleIcon style={{ fontSize: 17, paddingRight: 5 }} />
                ) : (
                  <RadioButtonUncheckedIcon
                    style={{ fontSize: 17, paddingRight: 5 }}
                  />
                )}
                <Field
                  type="radio"
                  name="category"
                  value={`${cat.name}`}
                  checked={cat.name === category}
                  onClick={(value) =>
                    changeCat(value.currentTarget.defaultValue)
                  }
                />
                {cat.name}
              </StyledLabel>
            )
            )}
          </div>
        </OptionDiv>

        <OptionDiv>
          <div id="checkbox-group">
            Groups (select one or more relevant tag)
          </div>
          <div role="group" aria-labelledby="checkbox-group">
            <div>
              {category
                ? Categories.filter(
                  (cat) => cat.name === category
                )[0].valueArr.map((subCat) => (
                  <BoxLabel>
                    <Field type="checkbox" name="tags" value={`${subCat}`} />
                    {subCat}
                  </BoxLabel>
                ))
                : null}
            </div>
          </div>
        </OptionDiv>
        <OptionDiv>
          <div>Word or Phrase*</div>
          {props.values.exerciseSegments.length > 0 && props.values.exerciseSegments.map((exSeg, key) => {
            return <MainWhiteContentContainer>
              <FlexRowDiv>
                <FlexRowDiv>
                  <MovieOutlinedIcon />
                  <FlexRowDivMargBotAndLeft>{exSeg.videoSegments[0].prompt.prompt}</FlexRowDivMargBotAndLeft>
                </FlexRowDiv>
                <IconButton
                  onClick={() =>
                    updateValues({
                      ...props.values,
                      exerciseSegments: props.values.exerciseSegments.splice(key, 1)
                    })
                  }
                  style={{ marginLeft: "auto", marginRight: 0 }}
                ><DeleteOutlineOutlinedIcon /></IconButton>
              </FlexRowDiv>
            </MainWhiteContentContainer>
          })}
          <Button
            to="/exercise-library/add/upload-instructions"
            disabled={disabled}
          >
            Upload Video
          </Button>
        </OptionDiv>
        <StyledBottomButton type="submit">+ Submit</StyledBottomButton>
        {props.errors?.title && <ErrorText>{props.errors?.title}</ErrorText>}
        {props.errors?.description && <ErrorText>{props.errors?.description}</ErrorText>}
        {props.errors?.category && <ErrorText>{props.errors?.category}</ErrorText>}
      </div>
    </Form>
  );
};

export default AddNewExcerciseForm;
