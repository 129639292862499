import React from 'react';

import TopBar from 'components/TopBar';

import usePromise from 'utils/hooks/usePromise';

import SFPatientClient from 'utils/datastore/patients';

const PatientAwareTopBar = ({ patientID }) => {
  const [_, patient] = usePromise(() => SFPatientClient.getPatient(patientID), [patientID]);
  return (<TopBar title={patient && `${patient.forename} ${patient.surname}`} />);
}

export default PatientAwareTopBar;