import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Icon } from '@rmwc/icon';
import '@rmwc/icon/styles';

const VPContainer = styled.div`
  position: relative;
  padding-top: 115%;
  background: black;
  border-radius: 10px;
  overflow: hidden;
`;

const VPControls = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: rgba(0, 0, 0, 0.4);
  opacity: ${props => props.playing ? 0 : 1};
  outline: none;
  border: 0;

  transition: 0.25s opacity ease-in-out;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const VideoPlayer = ({ src, playState, autoPlay, onVideoPositionUpdate, onCompleted }) => {
  const internalPlayState = useState(!!autoPlay);
  const [playing, setPlaying] = playState || internalPlayState;
  const playerRef = useRef(null);

  useEffect(() => {
    if (playerRef.current) {
      if (!playing !== playerRef.current.paused) {
        if (playing) {
          playerRef.current.play();
        } else {
          playerRef.current.pause();
        }
      }
    }
  }, [playing, playerRef, src]);

  useEffect(() => {
    if (playerRef.current && onVideoPositionUpdate) {
      const ref = playerRef.current;
      const callback = event => onVideoPositionUpdate(event.target.currentTime);
      ref.addEventListener('timeupdate', callback);
      return () => ref && ref.removeEventListener('timeupdate', callback);
    }
  }, [playerRef, onVideoPositionUpdate]);


  useEffect(() => {
    if (playerRef.current && onCompleted) {
      const ref = playerRef.current;
      const callback = () => {
        onCompleted();
      };
      ref.addEventListener('ended', callback);
      return () => ref && ref.removeEventListener('ended', callback);
    }
  }, [playerRef, onCompleted]);

  return <VPContainer>
    <VPControls playing={playing} onClick={() => setPlaying(!playing)}>
      <Icon icon={{ icon: playing ? "pause_circle" : "play_circle", size: "xlarge"}} />
    </VPControls>
    <Video
      ref={playerRef}
      src={src} />
  </VPContainer>
};

export default VideoPlayer;