import styled from 'styled-components';

const CardBase = styled.div`
  background: ${props => props.highlight ? "linear-gradient(184.31deg, #FDBF5E -19.75%, #FFFFFF 279.41%)" : "#FFFFFF"};
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: 1em;
  padding: 1.5rem;
  & > :first-child {
    margin-top: 0.5rem;
  }
`;

export default CardBase;