import { TextField as MaterialTextField } from '@rmwc/textfield';
import '@rmwc/textfield/styles';

const Input = ({ style, ...props }) => {
  return <MaterialTextField
      outlined
      {...props}
      />;
};

export default Input;
