import React from "react";

import BackToExcerciseDiv from "../../components/BackToExerciseDiv";
import { Subheading2 } from "../../components/Typography";
import ViewExercise from "../../components/ViewExercise";
import { MainWhiteContentContainer } from "../../GlobalStyles/GlobalStyles";

const AddNewExercisePage = () => {
  return (
    <>
      <h1>Add New Exercise</h1>
      <BackToExcerciseDiv
        linkLocation="/exercise-library"
        text="Exercise Library"
      />
      <MainWhiteContentContainer>
        <Subheading2>Exercise Details</Subheading2>
        <ViewExercise />
      </MainWhiteContentContainer>
    </>
  );
};

export default AddNewExercisePage;
