import React from 'react';

import icon from "../../assets/emptyProfileIcon.svg";
import {H2, Body, Body2} from '../../components/Typography'
import { ProfileCardBase, Image150px, StyledButton } from '../../GlobalStyles/GlobalStyles' 
import { SpacedEvenColDiv, RowDiv } from '../../components/Containers/'

const EmptyProfileCard = ({title, subTitle, email, ButtonTxt, imgUrl}) => <ProfileCardBase>
  <SpacedEvenColDiv>
  <Image150px style={{ alignSelf: 'center' }} src={imgUrl ? imgUrl : icon} alt="Patient Icon" />
  </SpacedEvenColDiv>

  <SpacedEvenColDiv>
    <H2>{title}</H2>
    <Body>{subTitle}</Body>
    {email ? <RowDiv>
      <Body2>Email: </Body2>
      <Body>ben@email.com</Body>
      </RowDiv> : null}
  <StyledButton to="/my-profile/edit" >{ButtonTxt}</StyledButton>
  </SpacedEvenColDiv>
</ProfileCardBase>

export default EmptyProfileCard;