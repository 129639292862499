import React from 'react';
import styled from 'styled-components';

import { Select } from '@rmwc/select';
import '@rmwc/select/styles';

const CustomSelect = styled(Select)`
  max-height: 50px;
  width: 100%;
  margin: 0 auto;
  & > * {
    max-height: 50px;
  }
`;

const ReviewedScoreDropdown = ({ value, onChange }) => <CustomSelect
  label="None"
  outlined
  options={[5,6,7,8,9,10].map(n => `${n*10}%`)}
  value={`${Math.round(value * 100)}%`}
  onChange={(evt) => onChange(Number(evt.currentTarget.value.split("%")[0]) / 100)}
  />

export default ReviewedScoreDropdown;