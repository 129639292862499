// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const VideoSegmentType = {
  "INSTRUCTIONAL": "instructional",
  "PHRASE_PROMPT": "phrase_prompt",
  "CONSONANT_PROMPT": "consonant_prompt"
};

const InviteStatus = {
  "PENDING": "pending",
  "ACCEPTED": "accepted",
  "REJECTED": "rejected"
};

const TherapyLevel = {
  "LOW": "low",
  "MODERATE": "moderate",
  "HIGH": "high"
};

const { Exercise, ExerciseSubmission, ExerciseFeedback, Invite, TherapyPlan, Patient, Therapist } = initSchema(schema);

export {
  Exercise,
  ExerciseSubmission,
  ExerciseFeedback,
  Invite,
  TherapyPlan,
  Patient,
  Therapist,
  VideoSegmentType,
  InviteStatus,
  TherapyLevel
};