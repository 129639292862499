
import styled from "styled-components";
import { Row as RawRow } from "components/Containers";
import { H3 as RawH3 } from "components/Typography";

export const H3 = styled(RawH3)`
  margin: 1em 0;
`;

export const Row = styled(RawRow)`
  flex-wrap: wrap;
`;

export const List = styled.div`
  border: 1px
      ${props => (props.isDraggingOver ? 'dashed #000' : 'solid #ddd')};
  background: #fff;
  padding: 0.5rem 0.5rem 0;
  border-radius: 3px;
  flex: 0 0 150px;
  font-family: sans-serif;
  padding: 0.75em 1em;
  min-width: 175px;
`;

export const Palette = styled(List)`
  background: #E5E5E5;
  border-radius: 5px;
  padding: 0.75em 1em;
  min-width: 200px;
  margin-bottom: 1em;
  margin-right: 1em;
  & > h4 {
    margin: 0.1em 0;
    margin-bottom: 0.5em;
  }
`;

export const Item = styled.div`
  display: flex;
  border-radius: 5px;
  user-select: none;
  padding: 0.5em 1em;
  padding-bottom: 1em;
  margin: 0 0 0.5rem 0;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  line-height: 1.5;
  background: #fff;
  border: 1px ${(props) => (props.isDragging ? "dashed #4099ff" : "solid #ddd")};
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);

  & h6 {
    margin: 0.2em 0;
    font-size: 0.8em;
    font-weight: 300;
  }
  & p {
    margin: 0.2em 0;
    font-size: 0.7em;
    font-weight: 300;
    opacity: 0.6;
  }
`;

export const Clone = styled(Item)`
  + div {
    display: none !important;
  }
`;

export const Container = styled(List)`
  border-radius: 7px;
  min-height: 100px;
`;

export const DayOfTheWeekContainer = styled.div`
  margin: 0 3em 2em 0;
  & > h4 {
    text-transform: uppercase;
    margin: 0.2em 0;
    font-weight: 500;
    font-size: 0.9em;
  }
`;

export const Notice = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 0.5rem;
  margin: 0 0.5rem 0.5rem;
  border: 1px solid transparent;
  line-height: 1.5;
  color: #aaa;
`;