import { API } from "aws-amplify";
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';

export default new (class {
  async fetchExistingTherapist(therapistID) {
    const variables = {
      id: therapistID,
    };
    const result = await API.graphql({
      query: queries.getTherapist,
      variables,
    });

    return result?.data?.getTherapist;
  }

  async createTherapist(therapistData) {
    console.log("In create therapist section");

    const variables = {
      input: {
        ...therapistData,
      },
    };

    const result = await API.graphql({
      query: mutations.createTherapist,
      variables,
    });
    return result;
  }

  async updateTherapist(therapist) {
    const variables = {
      input: {
        ...therapist,
      },
    };

    delete variables.input._deleted;
    delete variables.input._lastChangedAt;
    delete variables.input.updatedAt;
    delete variables.input.createdAt;
    delete variables.input.patients;
    delete variables.input.imageUri;
    delete variables.input.password;

    const result = await API.graphql({
      query: mutations.updateTherapist,
      variables,
    });
    return result?.data?.updateTherapist;
  }
})();