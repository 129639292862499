import SFInviteClient from "utils/datastore/therapists";

import { createTherapist, updateTherapist } from './crud';

export const onSubmit = async (values, user) => {

  const therapistID = user && user.attributes.sub;

  try {
    
    const existingTherapist = await fetchExistingTherapist(therapistID)
    
    if(!existingTherapist) {
      console.log('No therapist')
      await createTherapist(values, therapistID)
    } else {
      console.log('Found Therapist')
          await updateTherapist(existingTherapist, values)
          return existingTherapist;
    }
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
  }
}

export const fetchExistingTherapist = async (therapistID) => {
  const existingTherapist = await SFInviteClient.fetchExistingTherapist(therapistID);
  console.debug("Existing therapist found")
  return existingTherapist;
}