import styled from "styled-components";
import Button from "../Button";
import { ButtonText } from "../Typography";
import { signOut } from "../../utils/auth";

const TopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5em 1em;
  box-sizing: border-box;
  & h1 {
    color: #000000;
    font-size: 33px;
    margin: 0;
  }
`;

const TopBar = ({ title, forename }) => {
  return (
    <TopBarContainer>
      <h1>{title || `Welcome ${forename || ""} 👋`}</h1>
      <Button secondary onClick={() => signOut()}>
        <ButtonText>Log out</ButtonText>
      </Button>
    </TopBarContainer>
  );
};

export default TopBar;
