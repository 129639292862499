import React from "react";
import TopBarLayout from "../../layouts/TopBarLayout";
import { H1 } from "../../components/Typography";

import AddNewExcerciseCont from '../../components/AddNewExcercise'
import ExLibContainer from '../../components/ExLibContainer'

const ExerciseLibrary = () => {
  return (
    <div>
      <TopBarLayout screenName={"Exercise Library"} />
      <AddNewExcerciseCont />
      <ExLibContainer />
    </div>
  );
};

export default ExerciseLibrary;
