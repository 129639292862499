import React from "react";

import ExPlanNotDynamicBox from "./ExPlanNotDynamicBox";
import { FlexRow } from "components/Containers";

const ExPlanNotDynamic = ({ exPlan }) => {
  return (
    <div>
      <h3>Exercise Plan</h3>
      <FlexRow>
        {exPlan
          ? Object.entries(exPlan).map((entry) => {
              const [key, value] = entry;
              return <ExPlanNotDynamicBox day={key} exercises={value} />;
            })
          : null}
      </FlexRow>
    </div>
  );
};

export default ExPlanNotDynamic;
