import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import * as Components from './ui';

const ExercisePlanForm = ({ GROUPS, state, onDragEnd }) => {

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Components.H3>Exercise List</Components.H3>
      <Droppable droppableId="palette" isDropDisabled={true}>
        {(provided, snapshot) => (
          <Components.Row
            ref={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {GROUPS.map((group) => (
              <Components.Palette>
                <h4>{group.name}</h4>
                {group.items.map((item) => (
                  <Draggable key={item.id} draggableId={item.id} index={item.draggableIndex}>
                  {(provided, snapshot) => (
                    <React.Fragment>
                      <Components.Item
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        isDragging={snapshot.isDragging}
                        style={provided.draggableProps.style}
                      >
                        <h6>{item.content}</h6>
                        <p>{item.time || 5} minutes</p>
                      </Components.Item>
                      {snapshot.isDragging && <Components.Clone>
                        <h6>{item.content}</h6>
                        <p>{item.time || 5} minutes</p>
                      </Components.Clone>}
                    </React.Fragment>
                  )}
                </Draggable>
                ))}
              </Components.Palette>
            ))}
          </Components.Row>
        )}
      </Droppable>

      <Components.H3>Exercise Plan</Components.H3>
      <Components.Row>
        {Object.keys(state).map((list, i) => {
          return (<Components.DayOfTheWeekContainer>
            <h4>{list}</h4>
            <Droppable key={list} droppableId={list}>
              {(provided, snapshot) => (
                <Components.Container
                  ref={provided.innerRef}
                  isDraggingOver={snapshot.isDraggingOver}
                >
                  {state[list].length
                    ? state[list].map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Components.Item
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              isDragging={snapshot.isDragging}
                              style={provided.draggableProps.style}
                              {...provided.dragHandleProps}
                            >
                              <h6>{item.content}</h6>
                              <p>{item.time || 5} minutes</p>
                            </Components.Item>
                          )}
                        </Draggable>
                      ))
                    : !provided.placeholder && <Components.Notice>Drop items here</Components.Notice>}
                  {provided.placeholder}
                </Components.Container>
              )}
            </Droppable>
          </Components.DayOfTheWeekContainer>
          );
        })}
      </Components.Row>
    </DragDropContext>
  );
};

export default ExercisePlanForm;