import React, { useEffect, useState } from "react";

import ExerciseReview from "components/ExerciseReview";
import ReviewedScoreDropdown from "components/ReviewedScoreDropdown";

import useExerciseRecordings from "./hooks/useExerciseRecordings";
import useExerciseStatistics from "./hooks/useExerciseStatistics";
import useExerciseFeedback from "./hooks/useExerciseFeedback";
import useExercise from "./hooks/useExercise";

import { queue } from "utils/snackbar";
import SFFeedbackClient from "utils/datastore/exercise/feedback";

const enhanceFeedback = (feedback) => {
  if (!feedback.aiFeedback) {
    return {};
  }
  const aiFeedback = JSON.parse(feedback.aiFeedback);

  const flatFeedback = [].concat(...aiFeedback).filter((i) => !!i);

  const summaryFeedback = { vol: 0, pitch: 0, speed: "n/a" }

  flatFeedback.forEach(fb => {
    summaryFeedback.vol += (fb.feedback_detail?.volume?.mean) / flatFeedback.length || 0
    summaryFeedback.pitch += (fb.feedback_detail?.pitch?.mean) / flatFeedback.length || 0
  })

  console.log('flatFeedback: ', flatFeedback);

  const confusion = flatFeedback
    .map((feedback) => {
      if (feedback !== true) {
        return {
          target: feedback.exerciseDetail?.target || '',
          sortedConfidence: Object.entries(
            feedback.feedback_detail?.phoneme || {}
          ).sort((a, b) => b[1] - a[1]),
        }
      } else {
        return {
          target: 'tbc',
          sortedConfidence: {}
        }
      }
    })
    .filter((feedback) => feedback.sortedConfidence &&
      feedback.sortedConfidence[0] &&
      feedback.sortedConfidence[0][0] &&
      (feedback.target !== feedback.sortedConfidence[0][0]))
    .map((feedback) => ({
      target: `Ah-${feedback.target}ah`,
      result: `Ah-${feedback.sortedConfidence[0][0] || ''}ah`,
    }));

  return {
    ...feedback,
    pitch: {
      mean: Math.round(summaryFeedback.pitch),
    },
    volume: {
      mean: Math.round(summaryFeedback.vol),
    },
    speed: {
      mean: summaryFeedback.speed,
    },
    confusion,
  };
};

const ExerciseReviewContainer = ({ patientID, submissionID }) => {
  const [notes, setNotes] = useState("");
  const [reviewedScore, setReviewedScore] = useState(undefined);
  const [videoSequencePosition, setVideoSequencePosition] = useState(0);
  const [loadingVideos, recordings] = useExerciseRecordings(submissionID);
  const statistics = useExerciseStatistics(submissionID);
  const feedback = useExerciseFeedback(submissionID);
  const exerciseData = useExercise(submissionID);

  useEffect(() => {
    if (feedback) {
      console.log(feedback);
      if (feedback.therapistFeedback) {
        setNotes(feedback.therapistFeedback);
      }
      if (feedback.reviewedScore) {
        setReviewedScore(feedback.reviewedScore);
      }
    }
  }, [feedback]);

  const onSave = async () => {
    if (feedback && feedback.id) {
      try {
        let params = {
          therapistFeedback: notes,
        };
        if (reviewedScore) {
          params.reviewedScore = reviewedScore;
        }
        await SFFeedbackClient.updateFeedback(
          feedback.id,
          feedback._version,
          params
        );
        queue.notify({
          title: "Exercise Updated",
        });
      } catch (e) {
        console.error(e);
        queue.notify({
          title: <b>Something went wrong</b>,
          body: "Please try reviewing this exercise later.",
          icon: "warning",
        });
      }
    }
  };

  const pitch_data = [];
  const volume_data = [];

  if (feedback?.aiFeedback) {
    const aiFeedback = JSON.parse(feedback.aiFeedback);

    for (let f = 0; f < aiFeedback.length; f++) {
      for (let i = 0; i < aiFeedback[f].length; i++) {
        if (aiFeedback[f][i]?.exerciseDetail?.target) {
          if (aiFeedback[f][i]?.feedback_detail?.pitch?.mean) {
            pitch_data.push({
              x: aiFeedback[f][i].exerciseDetail?.target,
              y: aiFeedback[f][i].feedback_detail?.pitch?.mean,
            });
          }
          if (aiFeedback[f][i]?.feedback_detail?.volume?.mean) {
            volume_data.push({
              x: aiFeedback[f][i].exerciseDetail?.target,
              y: aiFeedback[f][i].feedback_detail?.volume?.mean,
            });
          }
        }
      }
    }
  }

  if (!feedback) {
    return null;
  }

  const exercise = exerciseData || {};

  const chartData = [
    {
      name: "Pitch Level",
      colour: "#021131",
      data: pitch_data,
    },
    {
      name: "Volume",
      colour: "#021131",
      data: volume_data,
    },
  ];

  const videoSequence = (recordings || []).map(([key, url]) => url);
  console.log('VIDEO SEQUENCE: ', videoSequence)

  const enhancedStatistics = statistics[0]?.data
    ? [
      {
        data: {
          ...statistics[0]?.data,
          reviewedScore: statistics[0]?.data?.reviewedScore || (
            <ReviewedScoreDropdown
              value={reviewedScore}
              onChange={setReviewedScore}
            />
          ),
        },
      },
    ]
    : statistics;
  console.log("Eh", enhancedStatistics);
  return (
    <ExerciseReview
      onSave={onSave}
      notes={notes}
      setNotes={setNotes}
      reviewedScore={reviewedScore}
      setReviewedScore={setReviewedScore}
      feedback={enhanceFeedback(feedback)}
      statistics={enhancedStatistics}
      chartData={chartData}
      unit={""}
      exercise={exercise}
      videoSequence={videoSequence}
      videoSequencePosition={videoSequencePosition}
      setVideoSequencePosition={setVideoSequencePosition}
    />
  );
};

export default ExerciseReviewContainer;
