import React, { useState, useMemo } from "react";
import styled from "styled-components";

import "@rmwc/tabs/styles";

import Card from "components/Card";
import LineChart from "components/LineChart";

import usePromise from "utils/hooks/usePromise";

import SFFeedbackClient from "utils/datastore/exercise/feedback";
import SFExerciseClient from "utils/datastore/exercise/";

import { getMonday, zeroPrefix } from "utils/date";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

import {
  WeekMonthTextBoxes,
  FlexSpaceBetweenDiv,
  FlexRowDiv,
} from "../../GlobalStyles/GlobalStyles";

import DateTimePicker from "react-datetime-pickers";
import "react-datetime-pickers/dist/index.css";

const GraphCard = styled(Card)`
  & h3 {
    margin-top: 0.25rem;
  }
`;

const DateTimePickerContainer = styled.div`
    & .react-datetime-pickers-overlay {
      right: 0;
    }
  `;

const TAB_OPTIONS = {
  weekly: 0,
  monthly: 1,
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

// Fixed date times not for reload
const useDate = () => {
  const now = new Date();
  const lastMonday = new Date(
    now.setDate(now.getDate() - ((now.getDay() + 6) % 7))
  );
  const firstOfMonth = new Date(new Date(now.getFullYear(), now.getMonth(), 1));

  return [lastMonday, firstOfMonth];
};

const PatientPerformanceOverviewChartCard = ({ patientID }) => {
  const [lastMonday, firstOfMonth] = useDate();
  const [calWeeklyDate, setCalWeeklyDate] = useState(lastMonday);

  const endOfWeek = useMemo(() => {
    let oneWeeksTime = new Date(calWeeklyDate);
    return new Date(oneWeeksTime.setDate(calWeeklyDate.getDate() + 7));
  }, [calWeeklyDate]);

  const [calMonthlyDate, setCalMonthlyDate] = useState(firstOfMonth);

  const endOfMonth = useMemo(() => {
    const year = calMonthlyDate.getYear();
    const month = calMonthlyDate.getMonth();
    return new Date(1900 + year, month + 1, 0);
  }, [calMonthlyDate]);

  const exDropDowns = GetExerciseNameAndIDPairs(patientID);

  const [exercise, setExercise] = useState();
  const [scoreType, setScoreType] = useState();

  const [activeTab, setActiveTab] = useState(TAB_OPTIONS.weekly);
  const [loadingWeek, weeklyData] = useWeeklyPatientsData(
    patientID,
    exercise,
    scoreType,
    calWeeklyDate,
    endOfWeek
  );

  let yAxisLabel;

  switch (scoreType) {
    case "speed":
      yAxisLabel = "Syllables per Second";
      break;
    case "pitch":
      yAxisLabel = "Pitch (Hz)";
      break;
    case "volume":
      yAxisLabel = "Volume (db)";
      break;
    default:
      yAxisLabel = "Avg %";
  }

  const maxGraphYAxisValue =
    weeklyData.length > 0
      ? Math.max.apply(
        Math,
        weeklyData.map(function (val) {
          return val.maxValue;
        })
      )
      : 100;

  const [loadingMonth, monthlyData] = useMonthlyPatientsData(
    patientID,
    exercise,
    scoreType,
    calMonthlyDate,
    endOfMonth
  );
  const [showDropDowns, setShowDropDowns] = useState(false);
  const classes = useStyles();

  if (loadingWeek || loadingMonth || !weeklyData || !monthlyData) {
    return null;
  }
  const data = [weeklyData, monthlyData];

  return (
    <GraphCard>
      <FlexSpaceBetweenDiv>
        <h3>Performance Overview</h3>
        <FlexRowDiv>
          <DateTimePickerContainer>
            <DateTimePicker
              selector={"week"}
              selected={calWeeklyDate}
              onChange={(val) => {
                setCalWeeklyDate(val);
                setActiveTab(0);
              }}
            >
              <WeekMonthTextBoxes>
                <h4>Weekly</h4>
              </WeekMonthTextBoxes>
            </DateTimePicker>
          </DateTimePickerContainer>

          <DateTimePickerContainer>
            <DateTimePicker
              selector={"month"}
              selected={calMonthlyDate}
              onChange={(val) => {
                setCalMonthlyDate(val);
                setActiveTab(1);
              }}
            >
              <h4>Monthly</h4>
            </DateTimePicker>
          </DateTimePickerContainer>
        </FlexRowDiv>
      </FlexSpaceBetweenDiv>

      {/* Radio buttons to hide/show drop downs */}
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="position"
          name="position"
          defaultValue="top"
        >
          <FormControlLabel
            value="scores"
            control={<Radio color="primary" />}
            label="Overall Scores"
            checked={!showDropDowns}
            onClick={() => {
              setShowDropDowns(false);
              setScoreType();
            }}
          />
          <FormControlLabel
            value="performance"
            control={<Radio color="primary" />}
            label="Overall Exercise Performance"
            onClick={() => {
              setShowDropDowns(true);
            }}
          />
        </RadioGroup>
      </FormControl>

      {/* Drop Downs to select  */}
      {showDropDowns ? (
        <div>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="exercise">Exercise</InputLabel>
            <Select
              labelId="exercise"
              id="exercise"
              value={exercise}
              onChange={(event) => setExercise(event.target.value)}
              label="Exercise"
            >
              {exDropDowns
                ? Object.entries(exDropDowns).map(([key, value]) => (
                  <MenuItem value={value}>{key}</MenuItem>
                ))
                : null}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="score">Score</InputLabel>
            <Select
              labelId="score"
              id="score"
              value={scoreType}
              onChange={(event) => setScoreType(event.target.value)}
              label="score"
            >
              <MenuItem value={"pitch"}>Pitch</MenuItem>
              <MenuItem value={"speed"}>Speed</MenuItem>
              <MenuItem value={"volume"}>Volume</MenuItem>
            </Select>
          </FormControl>
        </div>
      ) : null}

      {scoreType && maxGraphYAxisValue ? (
        <LineChart
          data={data[activeTab]}
          withLegend
          YAxisLabel={yAxisLabel}
          yAxisMaxVal={
            Math.round(maxGraphYAxisValue) +
            (5 - (Math.round(maxGraphYAxisValue) % 5))
          }
          unit={""}
        />
      ) : (
        <LineChart
          data={data[activeTab]}
          withLegend
          YAxisLabel={yAxisLabel}
          yAxisMaxVal={100}
          unit={"%"}
        />
      )}
    </GraphCard>
  );
};

export default PatientPerformanceOverviewChartCard;

const GetExerciseNameAndIDPairs = (patientID) => {
  // Get a list of all the relevant IDs from feedback
  const [isFeedbackLoading, feedback] = usePromise(
    () => SFFeedbackClient.fetchAllPatientsFeedback(patientID),
    []
  );

  // // Go to the main exercise list and fetch all excercises with the IDs above
  const exIDs = [];

  if (feedback?.length > 0) {
    feedback.map((fb) => exIDs.push(fb.exerciseID));
  }

  const [areAllExercisesLoading, allExercises] = usePromise(
    () => SFExerciseClient.fetchAllExcercises(exIDs),
    []
  );

  const exNameAndIDPair = {};

  if (allExercises && exIDs.length > 0) {
    allExercises
      .filter((ex) => exIDs.includes(ex.id))
      .forEach((val) => (exNameAndIDPair[val.title] = val.id));
  }

  return exNameAndIDPair;
};

const useWeeklyPatientsData = (
  patientID,
  exerciseID,
  scoreType,
  startDate,
  endDate
) => {
  const lastWeek = useMemo(() => getMonday(new Date()), []);

  const [isFeedbackLoading, feedback] = usePromise(
    () =>
      SFFeedbackClient.fetchFeedbackByExerciseAndDate(
        patientID,
        exerciseID,
        scoreType,
        startDate,
        endDate
      ),
    [patientID, startDate]
  );

  const data = useMemo(
    () => formatWeeklyData(feedback, scoreType),
    [feedback, scoreType]
  );

  return [isFeedbackLoading, data];
};

const formatWeeklyData = (feedback = [], scoreType) => {
  if (!feedback) {
    return [];
  }

  if (scoreType) {
    let maxValue;

    const enhancedFeedback = feedback.map((feedback) => {
      const parsedFB = JSON.parse(feedback.aiFeedback);
      const fullFB = parsedFB && parsedFB[0] && parsedFB[0][1];
      const fbDetails = fullFB?.feedback_detail;

      let score;

      if (scoreType === "speed") {
        score = fbDetails?.speech_duration;
        maxValue = fbDetails?.speech_duration;
      } else {
        score = fbDetails && fbDetails[scoreType] && fbDetails[scoreType].mean;
        maxValue = fbDetails?.[scoreType]?.q95;
      }

      return {
        ...feedback,
        meanScore: score,
        maxValue: maxValue,
        date: new Date(feedback.createdAt),
      };
    });

    return [
      {
        name: `${scoreType} average`,
        colour: "#4D5F87",
        data: createDayOfWeekScoreForKey("meanScore", enhancedFeedback),
        maxValue: maxValue,
      },
    ];
  } else {
    const enhancedFeedback = feedback.map((feedback) => ({
      ...feedback,
      aiScore: feedback.score && feedback.score * 100,
      reviewedScore: feedback.reviewedScore && feedback.reviewedScore * 100,
      selfAssessment:
        feedback.exerciseSubmission &&
        feedback.exerciseSubmission.selfAssessment &&
        (feedback.exerciseSubmission.selfAssessment + 1) * 10,
      date: new Date(feedback.createdAt),
    }));

    return [
      {
        name: "AI Score",
        colour: "#9AECE0",
        data: createDayOfWeekScoreForKey("aiScore", enhancedFeedback),
      },
      {
        name: "Self Assessment",
        colour: "#4D5F87",
        data: createDayOfWeekScoreForKey("selfAssessment", enhancedFeedback),
      },
      {
        name: "Reviewed Score",
        colour: "#068888",
        data: createDayOfWeekScoreForKey("reviewedScore", enhancedFeedback),
      },
    ];
  }
};

const filterByDateIndex = (index) => (feedback) =>
  feedback.date.getDay() === index;

const createDayOfWeekScoreForKey = (key, data) => {
  return [
    {
      x: "Monday",
      y: data
        .filter(filterByDateIndex(1))
        .map((d) => d[key])
        .filter((score) => !!score)
        .reduce((total, current, _, arr) => total + current / arr.length, 0),
    },
    {
      x: "Tuesday",
      y: data
        .filter(filterByDateIndex(2))
        .map((d) => d[key])
        .filter((score) => !!score)
        .reduce((total, current, _, arr) => total + current / arr.length, 0),
    },
    {
      x: "Wednesday",
      y: data
        .filter(filterByDateIndex(3))
        .map((d) => d[key])
        .filter((score) => !!score)
        .reduce((total, current, _, arr) => total + current / arr.length, 0),
    },
    {
      x: "Thursday",
      y: data
        .filter(filterByDateIndex(4))
        .map((d) => d[key])
        .filter((score) => !!score)
        .reduce((total, current, _, arr) => total + current / arr.length, 0),
    },
    {
      x: "Friday",
      y: data
        .filter(filterByDateIndex(5))
        .map((d) => d[key])
        .filter((score) => !!score)
        .reduce((total, current, _, arr) => total + current / arr.length, 0),
    },
  ];
};

const useMonthlyPatientsData = (
  patientID,
  exerciseID,
  scoreType,
  startDate,
  endDate
) => {
  const [isFeedbackLoading, feedback] = usePromise(
    () =>
      SFFeedbackClient.fetchFeedbackByExerciseAndDate(
        patientID,
        exerciseID,
        scoreType,
        startDate,
        endDate
      ),
    [patientID, exerciseID, scoreType, startDate, endDate]
  );

  const data = useMemo(
    () => formatMonthlyData(feedback, startDate, scoreType),
    [feedback, startDate, scoreType]
  );

  return [isFeedbackLoading, data];
};

const formatMonthlyData = (feedback = [], monthStart, scoreType) => {
  if (!feedback || !monthStart) {
    return [];
  }

  if (scoreType) {
    let maxValue;

    const enhancedFeedback = feedback.map((feedback) => {
      const parsedFB = JSON.parse(feedback.aiFeedback);
      const fullFB = parsedFB && parsedFB[0] && parsedFB[0][1];
      const fbDetails = fullFB?.feedback_detail;

      let score;

      if (scoreType === "speed") {
        score = fbDetails?.speech_duration;
        maxValue = fbDetails?.speech_duration;
      } else {
        score = fbDetails && fbDetails[scoreType] && fbDetails[scoreType].mean;
        maxValue = fbDetails?.[scoreType]?.q95;
      }

      return {
        ...feedback,
        meanScore: score,
        maxValue: maxValue,
        date: new Date(feedback.createdAt),
      };
    });

    return [
      {
        name: `${scoreType} average`,
        colour: "#4D5F87",
        data: createWeekScoreForKey("meanScore", enhancedFeedback, monthStart),
        maxValue: maxValue,
      },
    ];
  } else {
    const enhancedFeedback = feedback.map((feedback) => ({
      ...feedback,
      aiScore: feedback.score && feedback.score * 100,
      reviewedScore: feedback.reviewedScore && feedback.reviewedScore * 100,
      selfAssessment:
        feedback.exerciseSubmission &&
        feedback.exerciseSubmission.selfAssessment &&
        (feedback.exerciseSubmission.selfAssessment + 1) * 10,
      date: new Date(feedback.createdAt),
    }));

    return [
      {
        name: "AI Score",
        colour: "#9AECE0",
        data: createWeekScoreForKey("aiScore", enhancedFeedback, monthStart),
      },
      {
        name: "Self Assessment",
        colour: "#4D5F87",
        data: createWeekScoreForKey(
          "selfAssessment",
          enhancedFeedback,
          monthStart
        ),
      },
      {
        name: "Reviewed Score",
        colour: "#068888",
        data: createWeekScoreForKey(
          "reviewedScore",
          enhancedFeedback,
          monthStart
        ),
      },
    ];
  }
};

const filterByWeekIndex = (index, monthStart) => (feedback) =>
  Math.floor((feedback.date - monthStart) / (24 * 60 * 60 * 1000) / 7) ===
  index;

const getTitleForWeekByIndex = (index, monthStart) => {
  var date = new Date(monthStart);
  date.setDate(date.getDate() + index * 7);
  return `${zeroPrefix(date.getDate())}/${zeroPrefix(date.getMonth() + 1)}`;
};

const createWeekScoreForKey = (key, data, monthStart) => {
  const firstWeekInMonthStart = getMonday(monthStart);
  return [
    {
      x: getTitleForWeekByIndex(0, firstWeekInMonthStart),
      y: data
        .filter(filterByWeekIndex(0, firstWeekInMonthStart))
        .map((d) => d[key])
        .filter((score) => !!score)
        .reduce((total, current, _, arr) => total + current / arr.length, 0),
    },
    {
      x: getTitleForWeekByIndex(1, firstWeekInMonthStart),
      y: data
        .filter(filterByWeekIndex(1, firstWeekInMonthStart))
        .map((d) => d[key])
        .filter((score) => !!score)
        .reduce((total, current, _, arr) => total + current / arr.length, 0),
    },
    {
      x: getTitleForWeekByIndex(2, firstWeekInMonthStart),
      y: data
        .filter(filterByWeekIndex(2, firstWeekInMonthStart))
        .map((d) => d[key])
        .filter((score) => !!score)
        .reduce((total, current, _, arr) => total + current / arr.length, 0),
    },
    {
      x: getTitleForWeekByIndex(3, firstWeekInMonthStart),
      y: data
        .filter(filterByWeekIndex(3, firstWeekInMonthStart))
        .map((d) => d[key])
        .filter((score) => !!score)
        .reduce((total, current, _, arr) => total + current / arr.length, 0),
    },
    {
      x: getTitleForWeekByIndex(4, firstWeekInMonthStart),
      y: data
        .filter(filterByWeekIndex(4, firstWeekInMonthStart))
        .map((d) => d[key])
        .filter((score) => !!score)
        .reduce((total, current, _, arr) => total + current / arr.length, 0),
    },
  ].filter(
    (point) => Number(point.x.split("/")[1]) <= monthStart.getMonth() + 1
  );
};
