import usePromise from 'utils/hooks/usePromise';

import SFTherapistSubmissionAPI from 'utils/api/therapist/submissions';

const useExerciseRecordings = (submissionID) => {
  console.log('VIDEO SEQUENCE: get recordings: ');
  const [loading, videoURLs] = usePromise(() => SFTherapistSubmissionAPI.fetchTempVideoURLsByID(submissionID), []);
  return [loading, videoURLs && videoURLs.results];
}

export default useExerciseRecordings;