import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import LoginForm from "components/LoginForm";

import { signIn } from "utils/auth";
import { queue as messageQueue } from 'utils/snackbar';

const initialState = {
  username: "",
  password: ""
};

const LoginContainer = () => {
  const [formState, setFormState] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const onSubmit = async event => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);
    try {
      await signIn(formState.username, formState.password);
      setIsLoading(false);
      history.push("/");
    } catch (e) {
      console.error(e);
      messageQueue.notify({
        title: "Something went wrong...",
        body: e.message,
        icon: "warning"
      })
      setIsLoading(false);
    }
  }

  return <LoginForm
    isLoading={isLoading}
    formState={formState}
    onSubmit={onSubmit}
    onFieldUpdate={event => setFormState({
      ...formState,
      [event.target.name]: event.target.value
    })} />
}

export default LoginContainer;