import React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Body } from "../../components/Typography";
import { FlexRowDivMargBotAndLeft } from "../../GlobalStyles/GlobalStyles";

function CheckCircleAndText({ text }) {
  return (
    <FlexRowDivMargBotAndLeft>
      <CheckCircleIcon style={{ color: "#09C6AB" }} />
      <FlexRowDivMargBotAndLeft>{text}</FlexRowDivMargBotAndLeft>
    </FlexRowDivMargBotAndLeft>
  );
}

export default CheckCircleAndText;
