import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import SFExcerciseClient from "utils/datastore/exercise";
import usePromise from "utils/hooks/usePromise";
import SFPatientsClient from "utils/datastore/patients";
import SFTherapyPlanClient from "utils/datastore/therapyPlan";

import BackToExerciseDiv from "../../components/BackToExerciseDiv";
import { Subheading2, BlackLabel } from "../../components/Typography";
import CustomSelect from "../../components/CustomSelect";
import Button from "../../components/Button";
import ExercisePlanForm from "containers/ExercisePlanForm";
import { MainWhiteContentContainer } from "../../GlobalStyles/GlobalStyles";

const AssignExercise = ({ disabled, ...props }) => {
  const { id } = useParams();
  const history = useHistory();

  const [setExercise, exercise] = usePromise(
    () => SFExcerciseClient.getExercise(id),
    []
  );

  const patientIds = [];

  const [isPatientsLoading, patients] = usePromise(
    () => SFPatientsClient.fetchPatientsByIds(patientIds),
    []
  );

  const [patient, setPatient] = useState();

  const fullnames = [];

  if (patients) {
    patients.map((patient) =>
      fullnames.push({
        value: patient.id,
        label: `${patient.forename} ${patient.surname}`,
      })
    );
  }

  const orderPlan = (plan) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const dateNow = new Date();
    const dayToday = dateNow.getDay();

    const startOfWeek = daysOfWeek.splice(dayToday, 7);

    const newWeek = [...startOfWeek, ...daysOfWeek];

    const newPlan = {};

    for (let i = 0; i < newWeek.length; i++) {
      Object.assign(newPlan, { [newWeek[i]]: plan[newWeek[i]] });
    }

    return newPlan;
  };

  function onChangeInput(value) {
    if (patients && value[0]?.value) {
      const thisPatient = patients.filter((pat) => pat.id === value[0].value);
      const plan = JSON.parse(thisPatient[0].therapyPlan.exercisePlan);
      const orderedPlan = orderPlan(plan);
      setPatient(thisPatient);
      setExPlan(orderedPlan);
    } else {
      setPatient("");
    }
  }

  const [exPlan, setExPlan] = useState({});

  let items = [];

  if (exercise) {
    items = [
      {
        id: 1,
        eid: exercise.id,
        content: exercise.title,
        group: exercise.category,
      },
    ];
  }

  function handleChange(value) {
    setExPlan(value.target.value);

    const updatedPatient = patient;
    updatedPatient[0].therapyPlan.exercisePlan = JSON.stringify(
      value.target.value
    );
    setPatient(updatedPatient);
  }

  const submitChange = async () => {
    console.log("submit");
    const updatedPlan = await SFTherapyPlanClient.updateTherapyPlan(
      patient[0].therapyPlan
    );
    console.log(updatedPlan);
    if (updatedPlan) {
      history.push("/exercise-library/assigned");
    }
  };

  return (
    <>
      {exercise?.title ? <h1>{exercise.title}</h1> : null}
      <BackToExerciseDiv
        linkLocation={`/exercise-library/exercise/${id}`}
        text="Exercise Details"
      />
      <MainWhiteContentContainer>
        <Subheading2>Assign Exercise</Subheading2>
        <h1>{patient?.forename}</h1>
        <CustomSelect
          isMulti={true}
          onChange={onChangeInput}
          options={fullnames}
        />
        {patient ? (
          <>
            <ExercisePlanForm
              value={exPlan}
              name={"exercisePlan"}
              onChange={handleChange}
              items={items}
            />
            <Button type="button" onClick={() => submitChange()}>
              + Submit
            </Button>
          </>
        ) : null}
      </MainWhiteContentContainer>
    </>
  );
};

export default AssignExercise;
