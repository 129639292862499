import React from "react";

import PatientAwareTopBar from "containers/PatientAwareTopBar";

import Button from "components/Button";
import ExerciseReviewContainer from "containers/ExerciseReview";


const PatientSubmissionReview = ({ match: { params: { patientID, submissionID }} }) => {
  return (<>
      <PatientAwareTopBar patientID={patientID} />
      <Button regularCase to={`/patients/existing/${patientID}`} flat label="Back to Profile" icon="keyboard_backspace" />
      <ExerciseReviewContainer patientID={patientID} submissionID={submissionID} />
    </>
  );
};

export default PatientSubmissionReview;