import React from 'react';
import { Form } from "formik";
import TextField from "@material-ui/core/TextField";

import { Row } from "../../components/Containers";
import { H2 } from "../../components/Typography";
import Button from "../../components/Button";

import ExercisePlanForm from "containers/ExercisePlanForm";

const InviteForm = ({ disabled, ...props }) => <Form>
  <Row>
    <TextField
      id="firstName"
      name="firstName"
      label="First Name"
      disabled={disabled}
      value={props.values.firstName}
      onChange={props.handleChange("firstName")}
      onBlur={props.handleChange("firstName")}
      InputLabelProps={{
        shrink: true,
      }}
      style={{ marginRight: 100 }}
    />
    <TextField
      id="lastName"
      name="lastName"
      label="Last Name"
      disabled={disabled}
      value={props.values.lastName}
      onChange={props.handleChange("lastName")}
      onBlur={props.handleChange("lastName")}
      InputLabelProps={{
        shrink: true,
      }}
      style={{ marginRight: 100 }}
    />
  </Row>
  <Row>
    <TextField
      id="email"
      name="email"
      label="Email"
      disabled={disabled}
      value={props.values.email}
      onChange={props.handleChange("email")}
      onBlur={props.handleChange("email")}
      style={{ marginRight: 10 }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  </Row>
  <Row>
    <TextField
      multiline
      fullWidth
      rows={4}
      id="initial"
      name="initial"
      label="Initial Assessment"
      disabled={disabled}
      value={props.values.initial}
      onChange={props.handleChange("initial")}
      onBlur={props.handleChange("initial")}
      InputLabelProps={{
        shrink: true,
      }}
      style={{ marginRight: 100 }}
    />
    <TextField
      fullWidth
      multiline
      rows={4}
      id="therapy"
      name="therapy"
      label="Therapy Level"
      disabled={disabled}
      value={props.values.therapy}
      onChange={props.handleChange("therapy")}
      onBlur={props.handleChange("therapy")}
      InputLabelProps={{
        shrink: true,
      }}
    />
  </Row>
  <H2 subheader>Assign Exercises</H2>
  <ExercisePlanForm 
    disabled={disabled}
    value={props.values.exercisePlan}
    name={"exercisePlan"}
    onChange={props.handleChange("exercisePlan")} />
  <Button secondary disabled={disabled} type="submit">
    Submit
  </Button>
</Form>

export default InviteForm;