import React, { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import ExercisePlanForm from "components/ExercisePlanForm";

import { GROUPS } from "./content";
import { copy, reorder, move } from "./lib/helpers";

const ExercisePlanFormContainer = ({ value, name, onChange, items }) => {
  const [exercisePlanState, setExercisePlanState] = useState(
    value || {
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
      Sunday: [],
      DELETE: [],
    }
  );

  const setState = (value) => {
    setExercisePlanState(value);
    onChange({
      target: {
        value,
        name,
      },
    });
  };

  const deleteExercise = (source, destination) => {
    const itemToMove = exercisePlanState[source.droppableId][source.index];

    exercisePlanState[source.droppableId].splice(source.index, 1);

    if (destination) {
      exercisePlanState[destination.droppableId].splice(
        destination.index,
        0,
        itemToMove
      );
    }

    setState({ ...exercisePlanState });
  };

  const removeExerciseCheck = (source) => {
    confirmAlert({
      title: "This will remove the exercise from the plan",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteExercise(source),
        },
        {
          label: "No",
          onClick: null,
        },
      ],
    });
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination && exercisePlanState[source.droppableId]) {
      removeExerciseCheck(source);
    } else {
      // moving between two containers
      if (
        exercisePlanState[source.droppableId] &&
        exercisePlanState[destination.droppableId]
      ) {
        removeExerciseCheck(source, destination);
      } else {
        switch (source.droppableId) {
          // Removing from the day
          case "remove":
            console.log("Delete this item");
            break;

          // Moving from <x> to same <x> - so just re-order
          case destination.droppableId:
            setState({
              ...exercisePlanState,
              [destination.droppableId]: reorder(
                exercisePlanState[source.droppableId],
                source.index,
                destination.index
              ),
            });
            break;
          // Moving from palette to <x> - so clone the card
          case "palette":
            setState({
              ...exercisePlanState,
              [destination.droppableId]: copy(
                GROUPS(items).find((group) =>
                  group.items.find((i) => i.id === result.draggableId)
                ).items,
                exercisePlanState[destination.droppableId],
                source,
                destination
              ),
            });
            break;
          default:
            // Not a re-order or a copy, so let's move that card.
            setState({
              ...exercisePlanState,
              ...move(
                exercisePlanState[source.droppableId],
                exercisePlanState[destination.droppableId],
                source,
                destination
              ),
            });
            break;
        }
      }
    }
  };

  return (
    <ExercisePlanForm
      state={exercisePlanState}
      setState={setState}
      onDragEnd={onDragEnd}
      GROUPS={GROUPS(items)}
    ></ExercisePlanForm>
  );
};

export default ExercisePlanFormContainer;