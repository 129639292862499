import React from "react";

import BackToExcerciseDiv from "../../components/BackToExerciseDiv";
import { Subheading2 } from "../../components/Typography";
import AddNewExerciseContainer from "../../components/AddNewExerciseContainer";
import { MainWhiteContentContainer } from "../../GlobalStyles/GlobalStyles";

const AddNewExercisePage = ({ captureOrShare, history }) => {
  const { push } = history;
  return (
    <>
      {captureOrShare === "share" ? (
        <h1>Share - Submit your exercise when you're ready</h1>
      ) : (
        <h1>Add New Exercise</h1>
      )}

      <BackToExcerciseDiv
        linkLocation="/exercise-library"
        text="Exercise Library"
      />
      <MainWhiteContentContainer>
        <Subheading2>CAPTURE - Tell us about your exercise</Subheading2>
        <AddNewExerciseContainer push={push} />
      </MainWhiteContentContainer>
    </>
  );
};

export default AddNewExercisePage;
