import React from "react";

import { GreySmallBox, WhiteBox } from "../../GlobalStyles/GlobalStyles";

function ExPlanNotDynamicBox({ day, exercises }) {
  return (
    <div>
      <p>{day}</p>
      <GreySmallBox>
        {exercises && exercises.length > 0 ? (
          exercises.map((ex) => <WhiteBox>{ex.content}</WhiteBox>)
        ) : (
          <GreySmallBox>Nothing Assigned</GreySmallBox>
        )}
      </GreySmallBox>
    </div>
  );
}

export default ExPlanNotDynamicBox;
