
const Logo = ({ box, ...props }) => {
  if (box) {
    return <svg width="100%" height="100%" {...props} viewBox="0 0 130 106" fill="none">
      <path d="M109.688 6.7214e-05C108.562 -0.00297966 107.439 0.0975667 106.332 0.300692L10.1238 16.3882C4.26086 17.3957 -0.0184752 22.4885 5.99787e-05 28.4376V60.9375C-0.0172056 66.8916 4.27051 71.9865 10.1401 72.9869L13.4957 73.5475L0.430685 99.7507C-0.567928 101.76 0.251427 104.198 2.26059 105.197C3.5225 105.824 5.01928 105.752 6.21568 105.008L47.4907 79.2513L106.21 89.05C107.356 89.2679 108.521 89.3766 109.688 89.375C120.906 89.375 130 80.2809 130 69.0625V20.3126C130 9.09422 120.906 6.7214e-05 109.688 6.7214e-05Z" fill="#6DE4B9"/>
      <path d="M4.06256 105.625C1.81879 105.623 0.00183733 103.802 0.00437639 101.558C0.0051381 100.931 0.15088 100.312 0.430685 99.7506L13.4957 73.5475L10.1401 72.9869C4.27051 71.9868 -0.0169517 66.8916 5.99823e-05 60.9375V28.4376C-0.0184752 22.4885 4.26061 17.3954 10.1238 16.3882L106.332 0.300692C107.439 0.0975667 108.562 -0.00297966 109.688 6.7214e-05C120.906 6.7214e-05 130 9.09422 130 20.3126V69.0625C130 80.2809 120.906 89.375 109.688 89.375C108.521 89.3765 107.356 89.2679 106.21 89.05L47.4907 79.2513L6.21568 105.008C5.57 105.411 4.82402 105.625 4.06256 105.625ZM109.688 8.12506C109.056 8.12024 108.424 8.17457 107.803 8.28756L11.4807 24.3751C9.51646 24.7219 8.09459 26.4434 8.12506 28.4376V60.9375C8.09535 62.9345 9.52154 64.6568 11.4888 65L20.2476 66.4544C22.4603 66.8251 23.9538 68.9193 23.5834 71.1321C23.5171 71.5282 23.3924 71.9124 23.2132 72.2719L14.1213 90.4963L44.4925 71.5488C45.3299 71.0181 46.3351 70.8183 47.3119 70.9882L107.673 81.0632C108.336 81.1906 109.011 81.2531 109.688 81.25C116.419 81.25 121.875 75.7936 121.875 69.0625V20.3126C121.875 13.5818 116.419 8.12506 109.688 8.12506Z" fill={`${props.whiteOutline ? "#FFFFFF" : "#021131"}`}/>
      <ellipse cx="43.5" cy="46.0625" rx="5.5" ry="6" fill={`${props.whiteOutline ? "#FFFFFF" : "#021131"}`}/>
      <ellipse cx="66.5" cy="46.0625" rx="5.5" ry="6" fill={`${props.whiteOutline ? "#FFFFFF" : "#021131"}`}/>
      <ellipse cx="89.5" cy="46.0625" rx="5.5" ry="6" fill={`${props.whiteOutline ? "#FFFFFF" : "#021131"}`}/>
    </svg>;    
  }
  return null;
};

export default Logo;
 