import React, { useState, useEffect } from "react";
import {
  MainWhiteContentContainer,
  FlexRowDiv,
  TenPxMarginLeftDiv,
  FullWidthGreyBox,
  FlexColCenteredDiv,
  FlexGrowDiv,
} from "../../GlobalStyles/GlobalStyles";
import { Subheading2, Label } from "../../components/Typography";
import SearchBar from "material-ui-search-bar";
import Button from "../../components/Button";
import ExLibListItem from "../../components/ExLibListItem";
import TabMaker from "../../components/TabMaker";

import SFExcerciseClient from "utils/datastore/exercise";

import usePromise from "utils/hooks/usePromise";

const ExLibContainer = () => {
  const [searchValue, setSearchValue] = useState('');
  const [setExercises, exercises] = usePromise(
    () => SFExcerciseClient.fetchAllExcercises(),
    []
  );
  const [tab, setTab] = useState("phonemic");

  return (
    <MainWhiteContentContainer>
      <FlexRowDiv>
        <Subheading2>Exercise Library</Subheading2>
        <TenPxMarginLeftDiv>
          {exercises ? <Label>{exercises.length} total</Label> : null}
        </TenPxMarginLeftDiv>
      </FlexRowDiv>
      <SearchBar
        placeholder="Search exercise library..."
        onChange={(value) => setSearchValue(value)}
        style={{
          marginTop: 25,
          maxWidth: 668,
        }}
      />
      <FlexColCenteredDiv>
        <FlexGrowDiv onClick={() => setTab("phonemic")}>
          <TabMaker
            tab={tab}
            tabName={"phonemic"}
            fullTabName={"Phonemic Groups"}
          />
        </FlexGrowDiv>
        <FlexGrowDiv onClick={() => setTab("articulation")}>
          <TabMaker
            tab={tab}
            tabName={"articulation"}
            fullTabName={"Articulation / Fluency / Phrasing"}
          />
        </FlexGrowDiv>
        <FlexGrowDiv onClick={() => setTab("paralinguistic")}>
          <TabMaker
            tab={tab}
            tabName={"paralinguistic"}
            fullTabName={"Paralinguistic Drills"}
          />
        </FlexGrowDiv>
      </FlexColCenteredDiv>

      <FullWidthGreyBox>
        {exercises
          ? exercises
            .filter((ex) => ex.title.toLowerCase().includes(searchValue.toLowerCase()))
            .filter((ex) => ex.category === tab)
            .map((ex) => (
              <ExLibListItem
                key={ex.id}
                title={ex.title}
                id={ex.id}
                tags={JSON.parse(ex.tags)}
              />
            ))
          : null}
        {/* <Button>More</Button> */}
      </FullWidthGreyBox>
    </MainWhiteContentContainer>
  );
};

export default ExLibContainer;
