import React from "react";

import PatientAwareTopBar from "containers/PatientAwareTopBar";

import PatientExerciseOverview from "containers/PatientExerciseOverview";
import PatientProfileOverview from "containers/PatientProfileOverview";
import PatientExerciseWeek from "containers/PatientExerciseWeek";

const ExistingPatient = ({
  match: {
    params: { id },
  },
}) => {
  return (
    <>
      <PatientAwareTopBar patientID={id} />
      <PatientProfileOverview patientID={id} />
      <PatientExerciseWeek patientID={id} />
      <PatientExerciseOverview patientID={id} />
      <PatientExerciseOverview patientID={id} overvOrRec="record" />
    </>
  );
};

export default ExistingPatient;
