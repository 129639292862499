/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const syncTherapists = /* GraphQL */ `
  query SyncTherapists(
    $filter: ModelTherapistFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTherapists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        forename
        surname
        email
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        patients {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getTherapist = /* GraphQL */ `
  query GetTherapist($id: ID!) {
    getTherapist(id: $id) {
      id
      forename
      surname
      email
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      patients {
        items {
          id
          forename
          surname
          email
          initialAssessment
          aiScoreVisible
          patientGoal
          therapistID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listTherapists = /* GraphQL */ `
  query ListTherapists(
    $filter: ModelTherapistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTherapists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        forename
        surname
        email
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        patients {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getExercise = /* GraphQL */ `
  query GetExercise($id: ID!) {
    getExercise(id: $id) {
      id
      title
      description
      exerciseSegments
      category
      tags
      public
      delimiter
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listExercises = /* GraphQL */ `
  query ListExercises(
    $filter: ModelExerciseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExercises(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        category
        tags
        public
        delimiter
        exerciseSegments
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncExercises = /* GraphQL */ `
  query SyncExercises(
    $filter: ModelExerciseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncExercises(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        description
        exerciseSegments
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listExerciseSubmissions = /* GraphQL */ `
  query ListExerciseSubmissions(
    $filter: ModelExerciseSubmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExerciseSubmissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientID
        therapistID
        exerciseID
        exerciseFeedbackID
        started
        ended
        mood
        selfAssessment
        repeat
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getExerciseSubmission = /* GraphQL */ `
  query GetExerciseSubmission($id: ID!) {
    getExerciseSubmission(id: $id) {
      id
      patientID
      therapistID
      exerciseID
      exerciseFeedbackID
      started
      ended
      mood
      selfAssessment
      repeat
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const syncExerciseSubmissions = /* GraphQL */ `
  query SyncExerciseSubmissions(
    $filter: ModelExerciseSubmissionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncExerciseSubmissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        patientID
        therapistID
        exerciseID
        exerciseFeedbackID
        started
        ended
        mood
        selfAssessment
        repeat
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getExerciseFeedback = /* GraphQL */ `
  query GetExerciseFeedback($id: ID!) {
    getExerciseFeedback(id: $id) {
      id
      patientID
      therapistID
      exerciseID
      score
      reviewedScore
      repeat
      hasBeenReviewed
      aiFeedback
      therapistFeedback
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      exerciseSubmission {
        id
        patientID
        therapistID
        exerciseID
        exerciseFeedbackID
        started
        ended
        mood
        selfAssessment
        repeat
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const listExerciseFeedbacks = /* GraphQL */ `
  query ListExerciseFeedbacks(
    $filter: ModelExerciseFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExerciseFeedbacks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientID
        therapistID
        exerciseID
        score
        reviewedScore
        repeat
        hasBeenReviewed
        aiFeedback
        therapistFeedback
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        exerciseSubmission {
          id
          patientID
          therapistID
          exerciseID
          exerciseFeedbackID
          started
          ended
          mood
          selfAssessment
          repeat
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncExerciseFeedbacks = /* GraphQL */ `
  query SyncExerciseFeedbacks(
    $filter: ModelExerciseFeedbackFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncExerciseFeedbacks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        patientID
        therapistID
        exerciseID
        score
        reviewedScore
        repeat
        hasBeenReviewed
        aiFeedback
        therapistFeedback
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        exerciseSubmission {
          id
          patientID
          therapistID
          exerciseID
          exerciseFeedbackID
          started
          ended
          mood
          selfAssessment
          repeat
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getInvite = /* GraphQL */ `
  query GetInvite($id: ID!) {
    getInvite(id: $id) {
      id
      forename
      surname
      email
      initialAssessment
      aiScoreVisible
      patientGoal
      status
      therapistID
      therapistForename
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      therapyPlan {
        id
        patientID
        therapistID
        level
        active
        exercisePlan
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const listInvites = /* GraphQL */ `
  query ListInvites(
    $filter: ModelInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        forename
        surname
        email
        initialAssessment
        aiScoreVisible
        patientGoal
        status
        therapistID
        therapistForename
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        therapyPlan {
          id
          patientID
          therapistID
          level
          active
          exercisePlan
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInvites = /* GraphQL */ `
  query SyncInvites(
    $filter: ModelInviteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInvites(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        forename
        surname
        email
        initialAssessment
        aiScoreVisible
        patientGoal
        status
        therapistID
        therapistForename
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        therapyPlan {
          id
          patientID
          therapistID
          level
          active
          exercisePlan
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getPatient = /* GraphQL */ `
  query GetPatient($id: ID!) {
    getPatient(id: $id) {
      id
      forename
      surname
      email
      initialAssessment
      aiScoreVisible
      patientGoal
      therapistID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      therapyPlan {
        id
        patientID
        therapistID
        level
        active
        exercisePlan
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const listPatients = /* GraphQL */ `
  query ListPatients(
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        forename
        surname
        email
        initialAssessment
        aiScoreVisible
        patientGoal
        therapistID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        therapyPlan {
          id
          patientID
          therapistID
          level
          active
          exercisePlan
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPatients = /* GraphQL */ `
  query SyncPatients(
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPatients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        forename
        surname
        email
        initialAssessment
        aiScoreVisible
        patientGoal
        therapistID
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        therapyPlan {
          id
          patientID
          therapistID
          level
          active
          exercisePlan
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const listTherapyPlans = /* GraphQL */ `
  query ListTherapyPlans(
    $filter: ModelTherapyPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTherapyPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        patientID
        therapistID
        level
        active
        exercisePlan
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTherapyPlan = /* GraphQL */ `
  query GetTherapyPlan($id: ID!) {
    getTherapyPlan(id: $id) {
      id
      patientID
      therapistID
      level
      active
      exercisePlan
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const syncTherapyPlans = /* GraphQL */ `
  query SyncTherapyPlans(
    $filter: ModelTherapyPlanFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTherapyPlans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        patientID
        therapistID
        level
        active
        exercisePlan
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
