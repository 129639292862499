const Categories = [
  {
    name: "phonemic",
    title: "Phonemic Groups",
    valueArr: [
      "Bilabials",
      "Labio-dentals",
      "Lingua-aveolas",
      "Glottals",
      "Lingua-velars",
      "Lingua-palatals",
      "Blends",
      "Vowels",
    ],
  },
  {
    name: "articulation",
    title: "Articulation / Fluency / Phrasing",
    valueArr: [
      "Rhyming Words",
      "Syllabic Words",
      "Conversational Sentences",
      "Conversational Paragraphs/Narratives",
      "Increasing Word/Sentences Length",
      "Irregular Verbs",
      "Adjectives/Adverbs",
      "Similar Word Pairs",
      "Heteronyms",
      "Word Form Variations",
    ],
  },
  {
    name: "paralinguistic",
    title: "Paralinguistic Drills",
    valueArr: ["Varying Pitch", "Word Emphasis", "Emotions"],
  },
];
export default Categories;
