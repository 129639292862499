import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { TabBar, Tab } from '@rmwc/tabs';
import '@rmwc/tabs/styles';

import DataTable from 'components/DataTable';
import Card from 'components/Card';
import PatientStatusChip from 'components/PatientStatusChip';
import TabbedCardHeader from '../../components/TabbedCardHeader/index'
import PatientExerciseStatsRow from 'containers/PatientExerciseStatsRow';
import Button from 'components/Button';

import usePromise from 'utils/hooks/usePromise';

import SFFeedbackClient from 'utils/datastore/exercise/feedback';
import SFExerciseClient from 'utils/datastore/exercise';
import SFPatientClient from 'utils/datastore/patients';

import { getMonday, days, zeroPrefix } from 'utils/date';

const TableCard = styled(Card)`
`;

const columns = [
  {
    name: "Name",
    key: "name",
    weight: 10
  },
  {
    name: "Date",
    key: "date",
    weight: 10
  },
  {
    name: "Score",
    key: "score",
    weight: 10
  },
  {
    name: "Mood",
    key: "mood",
    weight: 10
  },
  {
    name: "Status",
    key: "status",
    weight: 15
  },
  {
    name: "Repeat?",
    key: "isRepeat",
    weight: 11
  },
  {
    name: <span>Self <br />Assessment</span>,
    key: "selfAssessment",
    weight: 17
  },
  {
    name: <span>Reviewed <br />Score</span>,
    key: "reviewedScore",
    weight: 17
  }
];

const TAB_OPTIONS = {
  week: 0,
  day: 1,
  upcoming: 2
}

const PatientExerciseOverview = ({ patientID, overvOrRec }) => {
  const [activeTab, setActiveTab] = useState(TAB_OPTIONS.week);

  const [isLoading, result] = usePatientsData(patientID);

  const tableData = useMemo(() => formatResultForTable(result), [result]);

  if (isLoading) {
    return null;
  }

  const recOrOvervTableData =
    overvOrRec === "record"
      ? tableData?.filter((data) => data.data?.reviewedScore != null)
      : tableData?.filter((data) => data.data?.reviewedScore == null);

  const finalTableData =
    activeTab === TAB_OPTIONS.week
      ? recOrOvervTableData
      : activeTab === TAB_OPTIONS.day
        ? recOrOvervTableData.filter(isToday(getToday()))
        : [];

  return (<TableCard>
    <TabbedCardHeader>
      <h4>Exercise {overvOrRec === "record" ? "Record" : "Overview"}</h4>
      <TabBar
        activeTabIndex={activeTab}
        onActivate={evt => setActiveTab(evt.detail.index)}>
        <Tab>This Year</Tab>
        <Tab>Today</Tab>
        <Tab>Upcoming</Tab>
      </TabBar>
    </TabbedCardHeader>
    {overvOrRec === 'record' ? null : <PatientExerciseStatsRow patientID={patientID} />}
    <DataTable data={finalTableData
      .map(row => ({
        ...row,
        href: `/patients/existing/${patientID}/submissions/${row.data.submissionID}`,
        data: {
          ...(row.data),
          reviewedScore: row.data.reviewedScore || <Button secondary to={`/patients/existing/${patientID}/submissions/${row.data.submissionID}`}>Action</Button>
        }
      }))} columns={columns} />
  </TableCard>);
}
export default PatientExerciseOverview;

const getToday = () => {
  const t = new Date();
  t.setHours(0, 0, 0, 0);
  return t;
}
const isToday = today => row => {
  return row.data.rawDate >= today;
}
const getOneYearAgo = date => {
  date.setMonth(date.getMonth() - 12);
  return date;
}

const MOODS = ["😩", "😞", "😌", "🙂", "😁"];

const formatResultForTable = (results = []) => {
  return results
    .map(submissionFeedback => ({
      data: {
        submissionID: submissionFeedback.exerciseSubmission.id,
        name: submissionFeedback.exercise?.title || '',
        rawDate: submissionFeedback.date,
        date: `${zeroPrefix(submissionFeedback.date.getDate())}/${zeroPrefix(submissionFeedback.date.getMonth() + 1)}/${submissionFeedback.date.getFullYear().toString().substr(-2)}`,
        score: submissionFeedback.score ? `${Math.round(submissionFeedback.score * 100)}%` : "tbc",
        mood: MOODS[submissionFeedback.exerciseSubmission.mood - 1],
        status: submissionFeedback.score <= 0.65 ? <PatientStatusChip.NeedsSupport /> : <PatientStatusChip.Good />,
        isRepeat: "No",
        selfAssessment: `${(submissionFeedback.exerciseSubmission.selfAssessment + 1) * 10}%`,
        reviewedScore: submissionFeedback.reviewedScore ? `${Math.round(submissionFeedback.reviewedScore * 100)}%` : null
      }
    }));
}

const usePatientsData = (patientID) => {
  const lastYear = useMemo(() => getOneYearAgo(new Date()), []);

  const [isFeedbackLoading, feedback] = usePromise(() => SFFeedbackClient.fetchFeedbackFromDate(patientID, lastYear), [patientID]);

  const exerciseIDs = useMemo(() => Object.keys((feedback || []).reduce((out, feedback) => ({ ...out, [feedback.exerciseID]: true }), {})), [feedback]);

  const [isExercisesLoading, exercises] = usePromise(() => SFExerciseClient.fetchExercisesByIds(exerciseIDs), [exerciseIDs]);

  const data = useMemo(() => formatInputsToData(feedback, exercises), [feedback, exercises])

  return [isFeedbackLoading || isExercisesLoading, data];
}

const formatInputsToData = (feedback = [], exercises = []) => {
  if (!feedback || !exercises) {
    return [];
  }

  return feedback
    .map(feedback => ({
      ...feedback,
      date: new Date(feedback.createdAt),
      exercise: exercises.find(ex => ex.id === feedback.exerciseID)
    }))
}


const useUpcomingExercises = (patientID) => {
  const [isPatientLoading, patient] = usePromise(() => SFPatientClient.getPatient(patientID), [patientID]);

  const data = useMemo(() => getUpcomingExerciseRows(patient), [patient])

  return [isPatientLoading, data];
}

const getUpcomingExerciseRows = (patient) => {
  if (!patient) {
    return [];
  }
  const exercisePlan = patient.therapyPlan && JSON.parse(patient.therapyPlan?.exercisePlan);

  if (!exercisePlan) {
    return [];
  }

  const currentDayIndex = new Date().getDay();
  const exercises = [].concat(...Object.entries(exercisePlan)
    .filter(([k, v]) => ((days.indexOf(k) - 1) % 7) > currentDayIndex)
    .map(([k, v]) => v));

  return exercises;
}