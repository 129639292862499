import React from 'react';
import styled from 'styled-components';

import Button from 'components/Button';

import Card from 'components/Card';

const PerfCard = styled(Card)`
 & h3 {
  margin-top: 0;
 }
`;

const AssignExerciseCard = ({ patientID }) => <PerfCard>
  <h3>Edit Therapy Plan</h3>
  <Button to={`/patients/existing/${patientID}/therapy-plan/edit`} raised>Edit</Button>
</PerfCard>;

export default AssignExerciseCard;