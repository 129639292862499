import { API } from "aws-amplify";
import * as queries from "graphql/queries";
import * as mutations from "graphql/mutations";

export default new (class {
  async fetchAllPatientsFeedback(patientID) {
    const variables = {
      filter: {
        patientID: {
          eq: patientID,
        },
      },
    };
    const result = await API.graphql({
      query: queries.listExerciseFeedbacks,
      variables,
    });
    return result?.data?.listExerciseFeedbacks?.items;
  }
  async fetchPendingFeedback(therapistID) {
    const variables = {
      filter: {
        therapistID: {
          eq: therapistID,
        },
        hasBeenReviewed: {
          eq: false,
        },
      },
      limit: 10000,
    };
    const result = await API.graphql({
      query: queries.listExerciseFeedbacks,
      variables,
    });
    return (
      result?.data?.listExerciseFeedbacks?.items &&
      result?.data?.listExerciseFeedbacks?.items
    );
  }
  async fetchAllFeedbackFromDate(therapistID, date) {
    const variables = {
      filter: {
        therapistID: {
          eq: therapistID,
        },
      },
      limit: 10000,
    };
    const result = await API.graphql({
      query: queries.listExerciseFeedbacks,
      variables,
    });
    return (
      result?.data?.listExerciseFeedbacks?.items &&
      result?.data?.listExerciseFeedbacks?.items
        .map((i) => ({ ...i, createdDate: new Date(i.createdAt) }))
        .filter((i) => i.createdDate >= date)
        .sort((a, b) => b.createdDate - a.createdDate)
    );
  }
  async fetchFeedbackFromDate(patientID, date, exerciseID) {
    const variables = {
      filter: {
        patientID: {
          eq: patientID,
        },
      },
      limit: 10000,
    };
    const result = await API.graphql({
      query: queries.listExerciseFeedbacks,
      variables,
    });

    let finalItems = result?.data?.listExerciseFeedbacks?.items;
    if (exerciseID) {
      finalItems = finalItems.filter((ex) => ex?.exerciseID === exerciseID);
    }

    return (
      finalItems &&
      finalItems
        .map((i) => ({ ...i, createdDate: new Date(i.createdAt) }))
        // .filter((i) => i.score != null)
        .filter((i) => i.createdDate >= date)
        .sort((a, b) => b.createdDate - a.createdDate)
    );
  }
  async fetchFeedbackByExerciseAndDate(
    patientID,
    exerciseID,
    scoreType,
    startDate,
    endDate = new Date()
  ) {
    const variables = {
      filter: {
        patientID: {
          eq: patientID,
        },
      },
      limit: 10000,
    };
    const result = await API.graphql({
      query: queries.listExerciseFeedbacks,
      variables,
    });

    let finalItems = result?.data?.listExerciseFeedbacks?.items;

    if (exerciseID) {
      finalItems = finalItems.filter((ex) => ex?.exerciseID === exerciseID);
    }
    return (
      finalItems &&
      finalItems
        .map((i) => ({ ...i, createdDate: new Date(i.createdAt) }))
        // .filter((i) => i.score != null)
        .filter((i) => new Date(i.createdDate).getTime() >= startDate.getTime())
        .filter((i) => new Date(i.createdDate).getTime() <= endDate.getTime())
        .sort((a, b) => b.createdDate - a.createdDate)
    );
  }
  async fetchFeedback(feedbackID) {
    const variables = {
      id: feedbackID,
    };
    const result = await API.graphql({
      query: queries.getExerciseFeedback,
      variables,
    });
    return result?.data?.getExerciseFeedback;
  }
  async updateFeedback(id, _version, { therapistFeedback, reviewedScore }) {
    const variables = {
      input: {
        _version,
        id,
        hasBeenReviewed: !!reviewedScore,
        therapistFeedback,
        reviewedScore,
      },
    };
    const result = await API.graphql({
      query: mutations.updateExerciseFeedback,
      variables,
    });
    return result?.data?.updateExerciseFeedback;
  }
})();
