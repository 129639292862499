import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { useExercise } from "state/exercise/hooks";

import { IconButton, TextField } from "@material-ui/core";
import styled from "styled-components";
import Amplify, { Storage } from "aws-amplify";
import awsconfig from "aws-exports";

import Button from "../../components/Button";
import { CircularSpinner } from "components/Loading";

Amplify.configure(awsconfig);

const UploadVideo = ({ push }) => {
  const StyledButton = styled(Button)`
    background-color: red;
  `;

  const [isLoading, setIsLoading] = useState(false);
  const [phraseData, setPhraseData] = useState({ title: null });
  const [videoData, setVideoData] = useState();
  const { formValues, updateValues } = useExercise();

  const createSegment = (uid, phraseData, uploadDuration, timestamps) => {
    const sayitwithmeDuration = 2.838;
    const sayitaloneDuration = 2.347;

    const sayitWithMeInstructionTriggerStart = 1;
    const sayitWithMeInstructionTriggerEnd = 2.5;

    const firstVideoSegmentEndTime = sayitwithmeDuration + (uploadDuration * 2);

    const start = timestamps[0];
    const end = timestamps[timestamps.length - 1];

    const startKey = Object.keys(start)[0]
    const endKey = Object.keys(end);

    const startWordTime = parseFloat(start[startKey].start_time);
    const endWordTime = parseFloat(end[endKey].end_time);

    const firstPromptStart = sayitWithMeInstructionTriggerEnd + startWordTime
    const firstPromptEnd = sayitWithMeInstructionTriggerEnd + endWordTime

    const secondPromptStart = sayitwithmeDuration + uploadDuration + startWordTime;
    const secondPromptEnd = sayitwithmeDuration + uploadDuration + endWordTime;

    const sayitaloneInstructionTriggerStart = firstVideoSegmentEndTime + 1.5;
    const sayitaloneInstructionTriggerEnd = firstVideoSegmentEndTime + 3;

    return {
      "type": "word",
      "videoFile": `https://sf-user-exercise-recordings115400-dev.s3.eu-west-2.amazonaws.com/public/${uid}/video-merged.mp4`,
      "videoSegments": [
        {
          "exerciseType": "word",
          "frame": {
            "end": firstVideoSegmentEndTime,
            "start": 0
          },
          "prompt": {
            "instruction": "Say it with me",
            "instructionTriggers": [
              [
                sayitWithMeInstructionTriggerStart,
                sayitWithMeInstructionTriggerEnd
              ]
            ],
            "instructionTriggerType": "word",
            "prompt": phraseData,
            "promptTriggers": [
              [
                firstPromptStart,
                firstPromptEnd,
              ],
              [
                secondPromptStart,
                secondPromptEnd
              ]
            ],
            "promptTriggerType": "phoneme"
          },
          "subtype": "start",
          "type": "chapter",
          "videoParams": {
            "muted": false
          }
        },
        {
          "exerciseType": "word",
          "frame": {
            "end": firstVideoSegmentEndTime + sayitaloneDuration + uploadDuration,
            "start": firstVideoSegmentEndTime
          },
          "prompt": {
            "instruction": "Say it alone",
            "instructionTriggers": [
              [
                sayitaloneInstructionTriggerStart,
                sayitaloneInstructionTriggerEnd
              ]
            ],
            "instructionTriggerType": "word",
            "prompt": phraseData,
            "promptTriggers": [
              [
                sayitaloneInstructionTriggerStart + startWordTime,
                sayitaloneInstructionTriggerStart + endWordTime
              ]
            ],
            "promptTriggerType": "phoneme"
          },
          "recording": {
            "range": [
              sayitaloneInstructionTriggerStart + 0.25,
              (sayitaloneInstructionTriggerStart + endWordTime) + 0.5
            ],
            "shouldRecord": true,
            "target": {
              "target": phraseData,
              "type": "speech"
            }
          },
          "subtype": "start",
          "type": "chapter",
          "videoParams": {
            "muted": false
          }
        }
      ]
    }
  }

  const getTranscribeTimes = async (uid) => {
    const executePoll = async (resolve, reject) => {
      let result = null

      try {
        result = await Storage.get(`${uid}/video.json`, { download: true, contentType: 'application/json' });
      }
      catch (e) {
        console.log('Error getting file: ', e)
      }

      if (!!result) {
        const res = await new Response(result.Body).json()
        return resolve(res);
      } else {
        setTimeout(executePoll, 5000, resolve, reject);
      }
    };
    return new Promise(executePoll);
  }

  const uploadVideo = async () => {
    setIsLoading(true)
    const uid = uuid();
    const file = videoData;
    const splt = file.name.split('.')
    const type = splt[splt.length - 1];

    try {
      const video = await loadVideo(file)
      await Storage.put(`${uid}/video.${type}`, file);
      getTranscribeTimes(uid).then((res) => {
        if (res) {
          const timestamps = JSON.parse(res).timestamps;
          const segment = createSegment(uid, phraseData.title, video.duration, timestamps);
          formValues.exerciseSegments.push(segment);
          updateValues(formValues);
          setIsLoading(false)
          push('/exercise-library/add')
        }
      });
    } catch (error) {
      setIsLoading(false)
      console.log("Error uploading file: ", error);
    }
  };

  const loadVideo = file => new Promise((resolve, reject) => {
    try {
      let video = document.createElement('video')
      video.preload = 'metadata'
      video.onloadedmetadata = function () {
        resolve(this)
      }
      video.onerror = function () {
        reject("Invalid video. Please select a video file.")
      }
      video.src = window.URL.createObjectURL(file)
    } catch (e) {
      reject(e)
    }
  })

  return (
    <div className="newSong">
      {isLoading && <CircularSpinner size="xlarge" />}
      {!isLoading && <>
        <TextField
          label="Word or Phrase"
          value={phraseData.title}
          onChange={(e) =>
            setPhraseData({ ...phraseData, title: e.target.value })
          }
        />
        <input
          type="file"
          accept="audio/mp4"
          onChange={(e) => setVideoData(e.target.files[0])}
        />
        <IconButton onClick={() => {
          if ((phraseData.title !== null && phraseData?.title?.trim() !== '') && videoData) {
            uploadVideo()
          } else {
            alert('Please add a word or phrase along with the video upload')
          }
        }}>
          <StyledButton>Upload</StyledButton>
        </IconButton>
      </>}
    </div>
  );
};

export default UploadVideo;
