import React from "react";

import BackToExerciseDiv from "../../components/BackToExerciseDiv";
import ExcerciseInstructionsDiv from "../../components/ExcerciseInstructionsDiv";

const AddNewExInstructionsPage = () => {
  return (
    <>
      <h1>Add New Exercise</h1>
      <BackToExerciseDiv linkLocation="/exercise-library" text="New Exercise" />
      <ExcerciseInstructionsDiv />
    </>
  );
};

export default AddNewExInstructionsPage;
