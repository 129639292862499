import React from 'react';
import styled from 'styled-components';

import EditableVerticalPatientProfileCard from "containers/EditableVerticalPatientProfileCard";
import PatientOverallPerfCard from 'containers/PatientOverallPerfCard';
import AssignExerciseCard from 'containers/AssignExerciseCard';
import PatientPerformanceOverviewChartCard from '../PatientPerformanceOverviewChartCard/index'

const Grid = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr auto;
  gap: 0px;
  grid-template-areas:
    "profile overall_perf assign_exercise"
    "profile perf_graph perf_graph";

  & > :first-child { grid-area: profile; }
  & > :nth-child(2) { grid-area: assign_exercise; }
  & > :nth-child(3) { grid-area: overall_perf; }
  & > :last-child { grid-area: perf_graph; }
`;

const PatientProfileOverview = ({ patientID }) => {
  return <Grid>
    <EditableVerticalPatientProfileCard patientID={patientID} />
    <PatientOverallPerfCard patientID={patientID} />
    <PatientPerformanceOverviewChartCard patientID={patientID} />
  </Grid>
}

export default PatientProfileOverview;