import { API } from "aws-amplify";
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';

export default new (class {
  async getTherapyPlan(id) {
    const result = await API.graphql({ query: queries.getTherapyPlan, variables: {
      id: id
    }});
    return result?.data?.getTherapyPlan;
  }
  async createTherapyPlan(therapyPlan) {
    const variables = {
      input: {
        ...therapyPlan
      }
    }
    console.log("Creating therapy plan with vars.... ", variables)
    const result = await API.graphql({ query: mutations.createTherapyPlan, variables });
    return result?.data?.createTherapyPlan;
  }
  async updateTherapyPlan(therapyPlan) {
    const variables = {
      input: {
        ...therapyPlan
      }
    }
    delete variables.input._deleted;
    delete variables.input._lastChangedAt;
    delete variables.input.updatedAt;
    delete variables.input.createdAt;
    console.log("Updating therapy plan with vars.... ", variables)
    const result = await API.graphql({ query: mutations.updateTherapyPlan, variables });
    return result?.data?.updateTherapyPlan;
  }
})();