import { API } from "aws-amplify";
import * as queries from 'graphql/queries';
// import * as mutations from 'graphql/mutations';

export default new (class {
  async fetchPatientsByIds(ids) {
    const filter = ids.reduce((out, id) => out ? ({
      id: {
        eq: id
      },
      or: out
    }) : ({
      id: {
        eq: id
      }
    }), null);
    const variables = {
      filter: filter,
      $limit: 30
    }
    const result = await API.graphql({ query: queries.listPatients, variables });
    return result?.data?.listPatients?.items && result?.data?.listPatients?.items;
  }
  async getPatient(id) {
    const variables = {
      id,
    }
    const result = await API.graphql({ query: queries.getPatient, variables });
    return result?.data?.getPatient;
  }
})();