import React from "react";

import TopBar from "components/TopBar";

import PatientsPendingHelpTable from 'containers/PatientsPendingHelpTable';
import HomeStatsRow from 'containers/HomeStatsRow';


const Home = () => {
  return (<>
      <TopBar />
      <HomeStatsRow />
      <PatientsPendingHelpTable />
    </>
  );
};

export default Home;
