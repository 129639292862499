
import SFInviteClient from "utils/datastore/invite";
import SFTherapyPlanClient from "utils/datastore/therapyPlan";

import { InviteStatus, TherapyLevel } from "models";

export const sendInviteWithValues = (values, therapistID, therapistName) => {
  const newInvite = {
    email: values.email,
    status: InviteStatus.PENDING,
    forename: values.firstName,
    surname: values.lastName,
    initialAssessment: values.initial,
    therapistID: therapistID,
    therapistForename: therapistName,

    aiScoreVisible: true,
    patientGoal: 0.8,

    therapyPlan: {
      patientID: "",
      therapistID: therapistID,
      level: TherapyLevel[values.therapy] || TherapyLevel.MODERATE,
      active: true,
      exercisePlan: JSON.stringify(values.exercisePlan),
    },
  };
  console.log("[INVITE] Sending invite with data....", newInvite);

  return SFInviteClient.createInvite(newInvite);
}

export const updateInviteWithValues = async (invite, values) => {
  console.log("[INVITE] Updating invite");
  const newInvite = {
    ...invite,
    forename: values.firstName,
    surname: values.lastName,
    initialAssessment: values.initial,
  };

  await SFInviteClient.updateInvite(newInvite);

  const updatedTherapyPlan = {
    ...invite.therapyPlan,
    level:
      TherapyLevel[values.therapy] ||
      TherapyLevel.MODERATE,
    active: true,
    exercisePlan: JSON.stringify(JSON.stringify(values.exercisePlan)),
  }

  await SFTherapyPlanClient.updatePlan(updatedTherapyPlan);
  console.log("[INVITE] Updated invite");
}

export const resendInvite = async invite => {
  const updatedInvite = {
    ...invite,
    status: InviteStatus.PENDING
  }
  await SFInviteClient.updateInvite(updatedInvite);
};