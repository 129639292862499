/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteExercise = /* GraphQL */ `
  mutation DeleteExercise(
    $input: DeleteExerciseInput!
    $condition: ModelExerciseConditionInput
  ) {
    deleteExercise(input: $input, condition: $condition) {
      id
      title
      description
      exerciseSegments
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createExerciseSubmission = /* GraphQL */ `
  mutation CreateExerciseSubmission(
    $input: CreateExerciseSubmissionInput!
    $condition: ModelExerciseSubmissionConditionInput
  ) {
    createExerciseSubmission(input: $input, condition: $condition) {
      id
      patientID
      therapistID
      exerciseID
      exerciseFeedbackID
      started
      ended
      mood
      selfAssessment
      repeat
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteExerciseFeedback = /* GraphQL */ `
  mutation DeleteExerciseFeedback(
    $input: DeleteExerciseFeedbackInput!
    $condition: ModelExerciseFeedbackConditionInput
  ) {
    deleteExerciseFeedback(input: $input, condition: $condition) {
      id
      patientID
      therapistID
      exerciseID
      score
      reviewedScore
      repeat
      hasBeenReviewed
      aiFeedback
      therapistFeedback
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      exerciseSubmission {
        id
        patientID
        therapistID
        exerciseID
        exerciseFeedbackID
        started
        ended
        mood
        selfAssessment
        repeat
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteInvite = /* GraphQL */ `
  mutation DeleteInvite(
    $input: DeleteInviteInput!
    $condition: ModelInviteConditionInput
  ) {
    deleteInvite(input: $input, condition: $condition) {
      id
      forename
      surname
      email
      initialAssessment
      aiScoreVisible
      patientGoal
      status
      therapistID
      therapistForename
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      therapyPlan {
        id
        patientID
        therapistID
        level
        active
        exercisePlan
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const deletePatient = /* GraphQL */ `
  mutation DeletePatient(
    $input: DeletePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    deletePatient(input: $input, condition: $condition) {
      id
      forename
      surname
      email
      initialAssessment
      aiScoreVisible
      patientGoal
      therapistID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      therapyPlan {
        id
        patientID
        therapistID
        level
        active
        exercisePlan
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const createTherapist = /* GraphQL */ `
  mutation CreateTherapist(
    $input: CreateTherapistInput!
    $condition: ModelTherapistConditionInput
  ) {
    createTherapist(input: $input, condition: $condition) {
      id
      forename
      surname
      email
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      patients {
        items {
          id
          forename
          surname
          email
          initialAssessment
          aiScoreVisible
          patientGoal
          therapistID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateTherapist = /* GraphQL */ `
  mutation UpdateTherapist(
    $input: UpdateTherapistInput!
    $condition: ModelTherapistConditionInput
  ) {
    updateTherapist(input: $input, condition: $condition) {
      id
      forename
      surname
      email
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      patients {
        items {
          id
          forename
          surname
          email
          initialAssessment
          aiScoreVisible
          patientGoal
          therapistID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteTherapist = /* GraphQL */ `
  mutation DeleteTherapist(
    $input: DeleteTherapistInput!
    $condition: ModelTherapistConditionInput
  ) {
    deleteTherapist(input: $input, condition: $condition) {
      id
      forename
      surname
      email
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      patients {
        items {
          id
          forename
          surname
          email
          initialAssessment
          aiScoreVisible
          patientGoal
          therapistID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteTherapyPlan = /* GraphQL */ `
  mutation DeleteTherapyPlan(
    $input: DeleteTherapyPlanInput!
    $condition: ModelTherapyPlanConditionInput
  ) {
    deleteTherapyPlan(input: $input, condition: $condition) {
      id
      patientID
      therapistID
      level
      active
      exercisePlan
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createExercise = /* GraphQL */ `
  mutation CreateExercise(
    $input: CreateExerciseInput!
    $condition: ModelExerciseConditionInput
  ) {
    createExercise(input: $input, condition: $condition) {
      id
      title
      description
      exerciseSegments
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateExercise = /* GraphQL */ `
  mutation UpdateExercise(
    $input: UpdateExerciseInput!
    $condition: ModelExerciseConditionInput
  ) {
    updateExercise(input: $input, condition: $condition) {
      id
      title
      description
      exerciseSegments
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateExerciseSubmission = /* GraphQL */ `
  mutation UpdateExerciseSubmission(
    $input: UpdateExerciseSubmissionInput!
    $condition: ModelExerciseSubmissionConditionInput
  ) {
    updateExerciseSubmission(input: $input, condition: $condition) {
      id
      patientID
      therapistID
      exerciseID
      exerciseFeedbackID
      started
      ended
      mood
      selfAssessment
      repeat
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteExerciseSubmission = /* GraphQL */ `
  mutation DeleteExerciseSubmission(
    $input: DeleteExerciseSubmissionInput!
    $condition: ModelExerciseSubmissionConditionInput
  ) {
    deleteExerciseSubmission(input: $input, condition: $condition) {
      id
      patientID
      therapistID
      exerciseID
      exerciseFeedbackID
      started
      ended
      mood
      selfAssessment
      repeat
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createExerciseFeedback = /* GraphQL */ `
  mutation CreateExerciseFeedback(
    $input: CreateExerciseFeedbackInput!
    $condition: ModelExerciseFeedbackConditionInput
  ) {
    createExerciseFeedback(input: $input, condition: $condition) {
      id
      patientID
      therapistID
      exerciseID
      score
      reviewedScore
      repeat
      hasBeenReviewed
      aiFeedback
      therapistFeedback
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      exerciseSubmission {
        id
        patientID
        therapistID
        exerciseID
        exerciseFeedbackID
        started
        ended
        mood
        selfAssessment
        repeat
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateExerciseFeedback = /* GraphQL */ `
  mutation UpdateExerciseFeedback(
    $input: UpdateExerciseFeedbackInput!
    $condition: ModelExerciseFeedbackConditionInput
  ) {
    updateExerciseFeedback(input: $input, condition: $condition) {
      id
      patientID
      therapistID
      exerciseID
      score
      reviewedScore
      repeat
      hasBeenReviewed
      aiFeedback
      therapistFeedback
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      exerciseSubmission {
        id
        patientID
        therapistID
        exerciseID
        exerciseFeedbackID
        started
        ended
        mood
        selfAssessment
        repeat
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const createInvite = /* GraphQL */ `
  mutation CreateInvite(
    $input: CreateInviteInput!
    $condition: ModelInviteConditionInput
  ) {
    createInvite(input: $input, condition: $condition) {
      id
      forename
      surname
      email
      initialAssessment
      aiScoreVisible
      patientGoal
      status
      therapistID
      therapistForename
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      therapyPlan {
        id
        patientID
        therapistID
        level
        active
        exercisePlan
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateInvite = /* GraphQL */ `
  mutation UpdateInvite(
    $input: UpdateInviteInput!
    $condition: ModelInviteConditionInput
  ) {
    updateInvite(input: $input, condition: $condition) {
      id
      forename
      surname
      email
      initialAssessment
      aiScoreVisible
      patientGoal
      status
      therapistID
      therapistForename
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      therapyPlan {
        id
        patientID
        therapistID
        level
        active
        exercisePlan
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const createPatient = /* GraphQL */ `
  mutation CreatePatient(
    $input: CreatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    createPatient(input: $input, condition: $condition) {
      id
      forename
      surname
      email
      initialAssessment
      aiScoreVisible
      patientGoal
      therapistID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      therapyPlan {
        id
        patientID
        therapistID
        level
        active
        exercisePlan
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updatePatient = /* GraphQL */ `
  mutation UpdatePatient(
    $input: UpdatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    updatePatient(input: $input, condition: $condition) {
      id
      forename
      surname
      email
      initialAssessment
      aiScoreVisible
      patientGoal
      therapistID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      therapyPlan {
        id
        patientID
        therapistID
        level
        active
        exercisePlan
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const createTherapyPlan = /* GraphQL */ `
  mutation CreateTherapyPlan(
    $input: CreateTherapyPlanInput!
    $condition: ModelTherapyPlanConditionInput
  ) {
    createTherapyPlan(input: $input, condition: $condition) {
      id
      patientID
      therapistID
      level
      active
      exercisePlan
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateTherapyPlan = /* GraphQL */ `
  mutation UpdateTherapyPlan(
    $input: UpdateTherapyPlanInput!
    $condition: ModelTherapyPlanConditionInput
  ) {
    updateTherapyPlan(input: $input, condition: $condition) {
      id
      patientID
      therapistID
      level
      active
      exercisePlan
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
