import styled from "styled-components";

export const BigContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 100%;
  padding: 1em;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px 0px;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SpacedEvenColDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const RowDiv = styled.div`
  display: flex;
`;

export const CenteredFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
