import React from "react";

import BackToExcerciseDiv from "../../components/BackToExerciseDiv";
import { Subheading2 } from "../../components/Typography";
import UploadVideo from "../../components/UploadVideo";
import { MainWhiteContentContainer } from "../../GlobalStyles/GlobalStyles";

const AddWordAndVideo = ({ history }) => {
  return (
    <>
      <h1>Add Word and Video</h1>
      <BackToExcerciseDiv
        linkLocation="/exercise-library"
        text="Exercise Library"
      />
      <MainWhiteContentContainer>
        <Subheading2>
          UPLOAD - Tell us about your chosen word or phrase
        </Subheading2>
        <UploadVideo push={history.push} />
      </MainWhiteContentContainer>
    </>
  );
};

export default AddWordAndVideo;
