import React from "react";
import {
  MainWhiteContentContainer,
  FlexBorderedCenteredDiv,
  ArrowRight,
  DarkBlueCircleDiv,
  FlexColDiv,
  CenteredFlexDiv,
  CapEditShareDivs,
} from "../../GlobalStyles/GlobalStyles";
import { Subheading2 } from "../Typography";
import Button from "../Button";

const ExcerciseInstructionsDiv = () => (
  <>
    <MainWhiteContentContainer>
      <Subheading2>Exercise Instructions</Subheading2>
      <FlexBorderedCenteredDiv>
        <CapEditShareDivs>
          <DarkBlueCircleDiv>1</DarkBlueCircleDiv>
          <FlexColDiv>
            <Subheading2>Capture</Subheading2>
            <p>Tell us about the exercise you're about to upload</p>
          </FlexColDiv>
          <ArrowRight />
        </CapEditShareDivs>

        <CapEditShareDivs>
          <DarkBlueCircleDiv>2</DarkBlueCircleDiv>
          <FlexColDiv>
            <Subheading2>Edit</Subheading2>
            <p>Record, upload and edit your exercise</p>
          </FlexColDiv>
          <ArrowRight />
        </CapEditShareDivs>

        <CapEditShareDivs>
          <DarkBlueCircleDiv>3</DarkBlueCircleDiv>
          <FlexColDiv>
            <Subheading2>Share</Subheading2>
            <p>Review, save and sahre your exercise to the library</p>
          </FlexColDiv>
        </CapEditShareDivs>
      </FlexBorderedCenteredDiv>
      <CenteredFlexDiv>
        <Button to={"/exercise-library/add"}>Next</Button>
      </CenteredFlexDiv>
    </MainWhiteContentContainer>
  </>
);

export default ExcerciseInstructionsDiv;
