import React from "react";
import styled from "styled-components";

import Card from "components/Card";
import Button from "components/Button";

import usePromise from "utils/hooks/usePromise";

import SFPatientClient from "utils/datastore/patients";

const ProfileCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & h3 {
    margin-top: 0;
  }
  ul {
    font-size: 0.9rem;
    list-style: none;
    padding-left: 0;
  }
  li {
    margin: 1rem 0;
  }
  li i {
    font-style: normal;
    color: #979797;
    margin-right: 1rem;
  }
`;
const options = { month: "short", year: "numeric" };
const dateFormatter = new Intl.DateTimeFormat("en-GB", options);

const EditableVerticalPatientProfileCard = ({ patientID }) => {
  const [loading, patient] = usePromise(
    () => SFPatientClient.getPatient(patientID),
    [patientID]
  );

  if (!patient) {
    return null;
  }
  return (
    <ProfileCard>
      <div>
        <h3>Patient Profile</h3>
        <p>Joined in {dateFormatter.format(new Date(patient.createdAt))}</p>
        <ul>
          <li>
            <i>Email: </i>
            {patient.email}
          </li>
          <li>
            <i>Initial Assessment: </i> <br /> {patient.initialAssessment}
          </li>
          <li>
            <i>Therapy Level: </i> <br />{" "}
            {patient.therapyPlan ? patient.therapyPlan.level : "n/a"}
          </li>
        </ul>
      </div>
    </ProfileCard>
  );
};

export default EditableVerticalPatientProfileCard;
