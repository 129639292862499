import React, { useMemo, useContext } from 'react';
import styled from 'styled-components';

import StatsCard from "components/StatCard";
import AddPatientCard from "components/AddPatientCard";

import AuthContext from 'state/auth';

import usePromise from 'utils/hooks/usePromise';

import SFFeedbackClient from 'utils/datastore/exercise/feedback';
import SFPatientsClient from 'utils/datastore/patients';

const StatsGrid = styled.section`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const MyPatientStats = () => {
  const { user } = useContext(AuthContext);

  const therapistID = useMemo(() => user && user.attributes.sub, [user && user.attributes.sub]);

  const [isLoading, stats] = useStats(therapistID);

  if (isLoading) {
    return null;
  }

  return (<StatsGrid>
    <AddPatientCard />
    {stats.map(stat => <StatsCard key={stat.title} {...stat} />)}
  </StatsGrid>);
}

function getMonday(d) {
  d = new Date(d);
  const day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6:1);
  return new Date(d.setTime(diff));
}

function getLast24(d) {
  return new Date(d.setTime(d.getTime() - 24*60*60*1000));
}


const formatInputsToData = (feedbackPending = [], patients = []) => {

  const startOfWeek = getMonday(new Date(Date.now()));
  const last24 = getLast24(new Date(Date.now()));

  console.log(patients);

  if (!patients || !feedbackPending) {
    return [];
  }

  return patients
    .map(patient => ({
      ...patient,
      submissions: feedbackPending.filter(feedback => feedback.patientID === patient.id)
        .map(sub => ({
          ...sub,
          date: new Date(sub.createdAt)
        })),
    }))
    .map(patient => ({
      ...patient,
      score: patient.submissions.reduce((score, submission) => score + (submission.score || 0), 0) / patient.submissions.filter(sub => sub.score >= 0).length,
      joinedThisWeek: new Date(patient.createdAt) >= startOfWeek,
      usedInLast24Hrs: patient.submissions.filter(sub => sub.date >= last24).length > 0
    }));
}

const formatStatsFromData = (data = []) => {
  return [
    {
      stat: data.filter((patient) => patient.joinedThisWeek).length,
      title: "New Patients",
      description: "Joined this week"
    },
    {
      stat: data.reduce((count, d) => count + (d.usedInLast24Hrs ? 1 : 0), 0),
      title: "Users Active",
      description: "On the app in the last 24 hours",
    }
  ];
}

const useStats = (therapistID) => {
  const [isFeedbackLoading, feedbackPending] = usePromise(() => SFFeedbackClient.fetchPendingFeedback(therapistID), [therapistID]);

  const [isPatientsLoading, patients] = usePromise(() => SFPatientsClient.fetchPatientsByIds([]), []);

  console.log(patients);

  const data = useMemo(() => formatInputsToData(feedbackPending, patients), [feedbackPending, patients]);

  const stats = useMemo(() => formatStatsFromData(data), [data]);

  return [isFeedbackLoading || isPatientsLoading, stats];
}

export default MyPatientStats;