import React from "react";

import { Subheading2 } from "../../components/Typography";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {
  MainWhiteContentContainer,
  FlexRowDiv,
} from "../../GlobalStyles/GlobalStyles";
import BackToExerciseDiv from "../../components/BackToExerciseDiv";

const ExerciseAssigned = () => {
  return (
    <>
      <h1>Assign Exercise</h1>
      <BackToExerciseDiv
        linkLocation={"/exercise-library"}
        text={"Exercise Library"}
      />
      <MainWhiteContentContainer>
        <FlexRowDiv>
          <Subheading2>You have successfully assigned an exercise</Subheading2>
          <CheckBoxIcon style={{ color: "#09C6AB" }} />
        </FlexRowDiv>
      </MainWhiteContentContainer>
    </>
  );
};

export default ExerciseAssigned;
