import styled from "styled-components";
import { NavLink } from "react-router-dom";

import Logo from 'components/Logo';
import { Body } from '../Typography';

const SpeechFirstLogo = styled(Logo).attrs({ box: true })`
  height: 30px;
  width: 26px;
  margin-left: 5px;
`;

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #20365a;
  width: 100%;

  a {
    color: inherit;
    text-decoration: none;
  }
  
  ul {
    margin: 0;
    padding: 0;
    width: 100%;
    background: #20365a;
    p {
      padding: 30px 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      height: 40px;
    }
  }

  li {
    width: 100%;
    list-style: none;
    text-align: center;

    :hover {
      p {
        background: rgba(255,255,255,0.3);
      }
    }
  }

  .active {
    p {
      font-weight: bold;
      background: rgba(255,255,255,0.3);
    }
  }
`;

const SideBarContainer = styled.nav`
  background: #20365a;
  height: 100vh;
  width: 260px;
  color: #fff;
  padding: 1.5em 0;
  box-sizing: border-box;
  position: fixed;

  & h1 {
    text-align: center;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Nav = () => {
  return (
    <StyledNav>
      <ul>
        <li>
          <NavLink exact to="/" activeClassName="active">
            <Body>Home</Body>
          </NavLink>
        </li>
        <li>
          <NavLink to="/patients" activeClassName="active">
            <Body>My Patients</Body>
          </NavLink>
        </li>
        <li>
          <NavLink to="/exercise-library" activeClassName="active">
            <Body>Exercise Library</Body>
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/support" activeClassName="active">
            <Body>Support</Body>
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/my-profile" activeClassName="active">
            <Body>My Profile</Body>
          </NavLink>
        </li>
      </ul>
    </StyledNav>
  )
}

const SideBar = () => {
  return (
    <SideBarContainer>
      <Title>
        <h1>SpeechFirst</h1><SpeechFirstLogo whiteOutline noWidth/>
      </Title>
      <Nav />
    </SideBarContainer>
  );
};

export default SideBar;
