import React from 'react';
import styled from 'styled-components';

import { CircularSpinner } from "components/Loading";
import Logo from 'components/Logo';
import Button from 'components/Button';
import Input from 'components/Input';

const LoginContainer = styled.div`
  background: #20365A;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginCard = styled.div`
  background: white;
  overflow: hidden;
  padding: 2em;
  border-radius: 10px;
  width: 70%;
  max-width: 500px;
  min-height: 300px;
  transform: scale(1, 0.001);

  @keyframes login-card-appear {
    0% {
      transform: scale(1, 0.001);
    }
    10% {
      transform: scale(1, 0.001);
    }
    75% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes login-card-content-appear {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation: 1s login-card-appear ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  & > * {
    animation: 1s login-card-content-appear ease-in-out;
    animation-iteration-count: 1;
  }

  & > form {
    display: block;
  }
  & > form > div {
    text-align: center;
    margin: 1em auto;
  }
  & > form > a {
    text-align: center;
    margin: 1em auto;
    text-decoration: none;
  }
  & > form > label {
    display: inline-flex;
    width: 100%;
    margin: 0.5em auto;
  }

`;

const SpeechFirstLogo = styled(Logo).attrs({ box: true })`
  height: 75px;
  margin: 2em 0;
`;

const LoginForm = ({ formState = {}, isLoading, onSubmit, onFieldUpdate }) => <LoginContainer>
  <LoginCard>
    {!isLoading && <form onSubmit={onSubmit}>
      <SpeechFirstLogo />
      <Input label="Username" value={formState.username} type="email" name="username" onChange={onFieldUpdate}  autoComplete="username" />
      <Input label="Password" value={formState.password} type="password" name="password" onChange={onFieldUpdate} autoComplete="current-password" />
      <Button raised>Sign In</Button>
      <hr />
      <p>Or download the app here</p>
      <a href="https://speech-first.s3.eu-west-2.amazonaws.com/app-release.apk" download><Button type="button" raised>Download App</Button></a>
    </form>}
    {isLoading && <CircularSpinner size="xlarge" />}
  </LoginCard>
</LoginContainer>;

export default LoginForm;