import React, { useState } from "react";

const ExerciseContext = React.createContext(null);

const initialValues = {
  title: "",
  description: "",
  category: "",
  tags: [],
  isPublic: false,
  exerciseSegments: []
}

export const ExerciseProvider = ({ children }) => {
  const [formValues, setFormValues] = useState(initialValues);

  const updateValues = (values) => {
    setFormValues(values);
  };

  const resetValues = () => {

    setFormValues({
      title: "",
      description: "",
      category: "",
      tags: [],
      isPublic: false,
      exerciseSegments: []
    });
  };

  return (
    <ExerciseContext.Provider value={{ formValues, updateValues, resetValues }}>
      {children}
    </ExerciseContext.Provider>
  );
};

export const ExerciseConsumer = ExerciseContext.Consumer;

export default ExerciseContext;