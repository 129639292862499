import { useMemo } from "react";

import usePromise from "utils/hooks/usePromise";

import SFSubmissionClient from "utils/datastore/exercise/submission";
import SFExerciseClient from "utils/datastore/exercise";

const useExercise = (submissionID) => {
  const [loadingSubmission, submission] = usePromise(
    () => SFSubmissionClient.fetchSubmission(submissionID),
    []
  );
  const exerciseID = useMemo(
    () => submission && submission.exerciseID,
    [submission]
  );

  const [loadingFeedback, exercise] = usePromise(
    () =>
      exerciseID
        ? SFExerciseClient.getExercise(exerciseID)
        : Promise.resolve(null),
    [exerciseID]
  );

  if (!exercise || !submission) {
    return null;
  }

  return exercise;
};

export default useExercise;
