import React from "react";

import { Subheading2 } from "../../components/Typography";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {
  MainWhiteContentContainer,
  FlexRowDiv,
} from "../../GlobalStyles/GlobalStyles";

const ExerciseSubmitted = () => {
  return (
    <>
      <h1>Add New Exercise</h1>
      <MainWhiteContentContainer>
        <FlexRowDiv>
          <Subheading2>Your exercise has been submitted</Subheading2>
          <CheckBoxIcon style={{ color: "#09C6AB" }} />
        </FlexRowDiv>
      </MainWhiteContentContainer>
    </>
  );
};

export default ExerciseSubmitted;
