/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:8d303b64-a4ec-414b-a49b-e0248efe7430",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_K7YolGJvb",
    "aws_user_pools_web_client_id": "6qctmmq3k1pf3da0ja0316u434",
    "oauth": {},
    "aws_mobile_analytics_app_id": "69786a57ba354bf8ac836deb4adef05f",
    "aws_mobile_analytics_app_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://gekuoqy7qvcbdmlxhz55fd5osq.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-wgppcmxg7ffhrjjmqsm2ntqmgm",
    "aws_cloud_logic_custom": [
        {
            "name": "me",
            "endpoint": "https://j7atk6gi29.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "sfTherapistSubmission",
            "endpoint": "https://v8tyrjs5vc.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "sf-user-exercise-recordings115400-dev",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
