import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button as MaterialButton } from '@rmwc/button';
import '@rmwc/button/styles';

import { ButtonText } from "../Typography";

const ButtonContainer = styled.div`
  display: block;
  margin: 0.5em 0;
  & .mdc-button__label {
    ${props => props.regularCase ? "text-transform: none;" : ""}
  }
`;

const ButtonLink = styled(Link)`
  text-decoration: none;
`;

const defaultOnClickHandler = () => {
  console.log("You pressed a button");
};

const Button = ({ children, to, onClick, flat, secondary, style, raised, ...props }) => {

  if (to) {
    return <ButtonLink to={to}>
      <Button {...props} onClick={onClick} flat={flat} secondary={secondary} style={style} raised={raised}>
        {children}
      </Button>
    </ButtonLink>
  }

  return (<ButtonContainer regularCase={props.regularCase} style={style}>
    <MaterialButton
      unelevated={flat ? false : !secondary}
      raised={raised}
      outlined={secondary}
      onClick={onClick || defaultOnClickHandler}
      {...props}
    >
      <ButtonText>{children}</ButtonText>
    </MaterialButton>
  </ButtonContainer>);
};

export default Button;
