import React from "react";

import TopBar from "components/TopBar";

import MyPatientsTable from 'containers/MyPatientsTable';
import MyPatientStats from 'containers/MyPatientStats';


const Patients = () => {
  return (<>
      <TopBar title="My Patients" />
      <MyPatientStats />
      <MyPatientsTable />
    </>
  );
};

export default Patients;