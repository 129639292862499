import React, { useContext, useState } from "react";
import { Formik } from "formik";
import AuthContext from 'state/auth';

import TherapistProfileForm from "components/TherapistProfile";
import { onSubmit } from './utils/submit';


const TherapistProfileContainer = ({forename, surname, email}) => {

  const INITIAL_VALUES = {
    imageUri: "",
    forename: forename || "",
    surname: surname || "",
    email: email || "",
    password: "",
  };
  
  const [loading, setIsLoading] = useState(false);
  const { user } = useContext(AuthContext);

  if (!user) {
    return null;
  }

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={async (values) => {
        setIsLoading(true);
        await onSubmit(values, user);
        setIsLoading(false);
      }}
    >
      {(props) => (
        <TherapistProfileForm
           disabled={loading}
           {...props}
           forename={forename} surname={surname} email={email}
        />
      )}
    </Formik>
  );
};

export default TherapistProfileContainer;
