import styled from "styled-components";
import Card from "../components/Card";
import Button from "../components/Button";

export const ProfileCardBase = styled(Card)`
  display: flex;
  justify-content: space-around;
  font-size: 0.8rem;
  min-width: 15rem;
  padding: 1.5rem 3rem;

  & > div > * {
    margin: 0.25rem;
    display: flex;
  }
`;

export const Image150px = styled.img`
  max-width: 150px;
  max-height: 150px;
`;

export const CircleImage150px = styled.img`
  max-width: 150px;
  max-height: 150px;
  border-radius: 50%;
`;

export const StyledButton = styled(Button)`
  margin: 1rem 0px;
`;

export const AddNewExCont = styled.div`
  width: 330px;
  height: 169px;
  background-color: #ffffff;
  padding: 19px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 15px 15px 15px 15px;
`;

export const FlexColSpacedEvenDiv = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 27px;
`;

export const FlexColCenteredDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const CenteredFlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
`;

export const ExercisePlanContainer = styled.div`
  margin: 1em;
  padding: 22px 36px;
  background-color: #ffffff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
`;

export const MainWhiteContentContainer = styled.div`
  margin: 24px 0px;
  padding: 22px 36px;
  background-color: #ffffff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
`;

export const FlexGrowDiv = styled.div`
  flex-grow: 1;
`;

export const FlexRowDiv = styled.div`
  display: flex;
`;

export const FlexSpaceBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WeekMonthTextBoxes = styled.div`
  margin-right: 30px;
  color: #20365a;
  margin-top: 0px;
  display: flex;
`;

export const WeekMonthContainer = styled.div`
  display: flex;
  margin-top: 0px;
`;

export const FlexRowDivMargBotAndLeft = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-left: 25px;
`;

export const FlexColDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 193px;
  margin: 28px 0px;
`;

export const ExcerciseContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const TenPxMarginLeftDiv = styled.div`
  margin-left: 10px;
`;

export const GreyTextBox = styled.div`
  background-color: #e5e5e5;
  padding: 12px 29px;
  min-width: 00px;
  margin-top: 13px;
  border-radius: 15px 15px 0px 0px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

export const GreySmallBox = styled.div`
  background-color: #e5e5e5;
  padding: 1px 1px;
  min-width: 100px;
  max-width: 220px;
  margin: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const WhiteTextBox = styled.div`
  background-color: #ffffff;
  padding: 12px 29px;
  min-width: 00px;
  margin-top: 13px;
  border-radius: 15px 15px 0px 0px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  h4 {
    font-weight: normal;
  }
`;

export const WhiteBox = styled.div`
  background-color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  width: 100%;
  flex: 1 1 auto;
  margin: 5px;
`;

export const FullWidthGreyBox = styled.div`
  background-color: #e5e5e5;
  padding: 12px 29px;
  border-radius: 0px 0px 15px 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
`;

export const ExcerciseTags = styled.div`
  border: 1px solid #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin: 10px 10px 10px 0px;
  padding: 0px 10px;
`;

export const FlexBorderedCenteredDiv = styled.div`
  border: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  flex-wrap: wrap;
`;

export const CapEditShareDivs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  flex-wrap: wrap;
`;

export const ArrowRight = styled.div`
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 12px solid #e5e5e5;
`;

export const DarkBlueCircleDiv = styled.div`
  background: #021131;
  width: 50px;
  height: 50px;
  border-radius: 64px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
`;

export const ExampleVideoImage = styled.img`
  max-height: 350px;
  height: 50%;
`;
