import { uuid } from "uuidv4";

const HC_ITEMS = [
  {
    id: 1,
    eid: "c47aeea6-5201-47f3-b003-7fe54ecd9927",
    content: "Consonant Sounds",
    group: "Sounds",
  },
  {
    id: 2,
    eid: "5cc4155b-d6db-4295-9c08-e895e3625c66",
    content: "Consonant Pairs",
    group: "Sounds",
  },
  {
    id: 3,
    eid: "d2e8385c-b32e-49df-b6f7-428c8f6168c8",
    content: "Sustained Sounds",
    group: "Sounds",
  },
  {
    id: 4,
    eid: "20c799da-2b24-410f-ad4c-b17966ee4cfd",
    content: "Words",
    group: "Words and Phrases",
  },
  {
    id: 5,
    eid: "a652afdd-9339-46e6-8e58-5da48c90136e",
    content: "Phrases",
    group: "Words and Phrases",
  },
];
export const GROUPS = (items) => {
  const ITEMS = items || HC_ITEMS;
  return ITEMS.map((item, index) => ({ ...item, draggableIndex: index }))
    .reduce((groups, item) => {
      let existingItemGroup = groups.find((group) => group.name === item.group);
      if (!existingItemGroup) {
        existingItemGroup = {
          name: item.group,
          items: [item],
        };
      }

      const itemGroupIndex = groups.indexOf(existingItemGroup);

      if (itemGroupIndex >= 0) {
        const newArray = [].concat(groups);
        newArray[itemGroupIndex].items = [].concat(
          newArray[itemGroupIndex].items,
          [item]
        );
        return newArray;
      } else {
        return [].concat(groups, [existingItemGroup]);
      }
    }, [])
    .map((group) => ({
      ...group,
      items: group.items.map((i) => ({ ...i, exerciseId: i.id, id: i.eid })),
    }));
};
