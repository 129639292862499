import { Auth } from "aws-amplify";

export class UnauthorizedAccessError extends Error {

}

export const signUp = async (username, password, email) => {
  try {
    const { user } = await Auth.signUp({
      username,
      password,
      attributes: {
        email, // optional
      },
    });
    console.log(user);
  } catch (error) {
    console.log("error signing up:", error);
  }
};

export const confirmSignUp = async (username, code) => {
  try {
    await Auth.confirmSignUp(username, code);
  } catch (error) {
    console.log("error confirming sign up", error);
  }
};

export const signIn = async (username, password) => {
  try {
    const user = await Auth.signIn(username, password);
    const userGroups = user.signInUserSession.accessToken.payload["cognito:groups"];
    if (userGroups.indexOf("Therapists") < 0) {
      await signOut();
      throw new UnauthorizedAccessError("You are not authorized to access this application");
    } else {
      return user;
    }
  } catch (error) {
    console.log("error signing in", error);
    throw error;
  }
};

export const resendConfirmationCode = async (username) => {
  try {
    await Auth.resendSignUp(username);
    console.log("code resent successfully");
  } catch (err) {
    console.log("error resending code: ", err);
  }
};

export const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
};
