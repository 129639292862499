import React, { useEffect, useState } from 'react';

import Amplify, { Auth, Hub, Logger } from 'aws-amplify';

import awsconfig from "aws-exports";

const context = React.createContext(null);

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isLoggedIn: false,
    isLoaded: false,
    user: null
  });

  console.log(authState);
  useEffect(() => {
    const logger = new Logger('AuthProvider');
    
    const listener = async (data) => {
      const newAuthState = {
        ...authState,
        isLoaded: true,
        state: data.payload.event,
      };
      switch (data.payload.event) {
        case 'signIn':
            logger.info('user signed in');
            newAuthState.isLoggedIn = true;
            newAuthState.user = data.payload.data;
            break;
        case 'signUp':
            logger.info('user signed up');
            newAuthState.isLoggedIn = false;
            break;
        case 'signOut':
            logger.info('user signed out');
            newAuthState.isLoggedIn = false;
            break;
        case 'signIn_failure':
            logger.error('user sign in failed');
            newAuthState.isLoggedIn = false;
            break;
        case 'tokenRefresh':
            logger.info('token refresh succeeded');
            newAuthState.isLoggedIn = true;
            break;
        case 'tokenRefresh_failure':
            logger.error('token refresh failed');
            newAuthState.isLoggedIn = false;
            break;
        case 'configured':
            logger.info('the Auth module is configured');
            let authUser = null;
            try {
              await Auth.currentAuthenticatedUser();
            } catch (e) {
              // do nothing...
            }
            newAuthState.isLoggedIn = !!authUser;
            newAuthState.user = authUser;
            break;
        default:
          logger.info('unknown event', data);
      }
      setAuthState(newAuthState);
    }
    
    Hub.listen('auth', listener);

    console.log("Registered hub listener....")

    if (!authState.isLoaded) {
      Amplify.Logger.LOG_LEVEL = 'DEBUG'
      Amplify.configure(awsconfig);
    }
    
    return () => Hub.remove('auth', listener)

    
  }, [authState]);

  return (
    <context.Provider
      value={
        authState
      }
    >
      {children}
    </context.Provider>
  );
};

export default context;