import React, { useState, useEffect } from "react";
import styled from "styled-components";

import CardBase from "components/Card";
import SFSequenceVideoPlayer from "components/SequenceVideoPlayer";
import Textarea from "components/Textarea";
import DataTable from "components/DataTable";
import Button from "components/Button";

import LineChart from "components/LineChart";

const ReviewGrid = styled.section`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 4rem;
  grid-template-rows: auto auto;
  grid-template-areas:
    "video graph"
    "notes graph";
  & h5 {
    font-weight: normal;
  }
  & textarea {
    width: 100%;
  }

  & > :first-child {
    grid-area: video;
  }
  & > :nth-child(2) {
    grid-area: graph;
  }
  & > :last-child {
    grid-area: notes;
  }
`;

const AIScoreRow = styled.div`
  display: flex;
  align-items: center;
`;

const AIScoreCard = styled(CardBase)`
  border: 1px solid #e5e5e5;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0;
  padding: 0.5rem 1.5rem;
  /* flex: 1; */
  min-width: 100px;
  & > * {
    margin: 0;
  }
  & > :first-child {
    font-weight: bold;
    font-style: normal;
    margin: 0;
    margin-right: 1rem;
  }
  & > :last-child {
    font-size: 0.75rem;
    margin-left: 1rem;
  }
`;

const GreyAIScoreCard = styled(AIScoreCard)`
  background: #e5e5e5;
`;

const ImprovementList = styled.ul`
  margin: 0;
  list-style-type: none;
  padding-left: 5px;
  font-size: 0.7rem;
  & > li {
    text-indent: -5px;
    margin: 0.5rem 0;
  }
  & > li:before {
    content: "-";
    text-indent: -5px;
  }
`;

const ButtonBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0;
  & > * {
    margin-right: 2rem;
  }
`;

const stat_columns = [
  {
    name: "Date",
    key: "date",
    weight: 12,
  },
  {
    name: "Time Taken",
    key: "duration",
    weight: 12,
  },
  {
    name: "Score",
    key: "score",
    weight: 12,
  },
  {
    name: "Mood",
    key: "mood",
    weight: 12,
  },
  {
    name: "Status",
    key: "status",
    weight: 16,
  },
  {
    name: "Repeat?",
    key: "repeat",
    weight: 12,
  },
  {
    name: (
      <span>
        Self <br />
        Assessment
      </span>
    ),
    key: "selfAssessment",
    weight: 12,
  },
  {
    name: (
      <span>
        Reviewed <br />
        Score
      </span>
    ),
    key: "reviewedScore",
    weight: 12,
  },
];

const ExerciseReview = ({
  feedback = {},
  statistics = {},
  notes,
  setNotes,
  onSave,
  chartData,
  unit,
  exercise,
  videoSequence,
  videoSequencePosition,
  setVideoSequencePosition,
  patientID,
}) => {
  // removed confusion pre demo as the values were wrong. Kept the rest of the implementation in though so hopfully fixing is easier in the future.\
  // Removed areas of improvement #136 was the PR
  const confusion = feedback?.confusion || [];
  const [pitchOrVol, setPitchOrVol] = useState("pitch");
  const [filteredChartData, setFilteredChartData] = useState(chartData);
  const [maxGraphYAxisValue, setMaxYAxisValue] = useState(100);

  const PitchOrVolScoreCard = ({ pitchOrVol, PorV }) => {
    return pitchOrVol === PorV ? (
      <div onClick={() => setPitchOrVol(PorV)}>
        <GreyAIScoreCard>
          <i>{feedback[PorV].mean}</i>
          <p>Avg {PorV}</p>
        </GreyAIScoreCard>
      </div>
    ) : (
      <div onClick={() => setPitchOrVol(PorV)}>
        <AIScoreCard>
          <i>{feedback[PorV].mean}</i>
          <p>Avg {PorV}</p>
        </AIScoreCard>
      </div>
    );
  };

  useEffect(() => {
    if (chartData) {
      const newChartData = chartData.filter((data) =>
        data.name.toLowerCase().includes(pitchOrVol)
      );

      if (newChartData.length === 1 && newChartData[0].data?.length === 1) {
        newChartData[0].data.unshift({ x: [newChartData[0].data[0].x], y: 0 });
      }

      setFilteredChartData(newChartData);

      if (newChartData[0].data && newChartData[0].data.length > 0) {
        let newYAxisValue =
          Math.max.apply(
            Math,
            newChartData[0].data.map(function (o) {
              return o.y;
            })
          ) || 100;

        if (newYAxisValue % 10 !== 0) {
          newYAxisValue =
            Math.round(newYAxisValue) + (10 - (Math.round(newYAxisValue) % 10));
        }

        setMaxYAxisValue(newYAxisValue);
      }
    }
  }, [chartData, pitchOrVol]);
  return (
    <CardBase>
      <h3>{exercise.title} Exercise Results</h3>
      <ReviewGrid>
        <SFSequenceVideoPlayer
          sequence={videoSequence}
          activeIndex={videoSequencePosition}
          setActiveIndex={setVideoSequencePosition}
        />
        <div>
          <h5>Performance Scores</h5>
          <AIScoreRow>
            {feedback.pitch && (
              <PitchOrVolScoreCard pitchOrVol={pitchOrVol} PorV="pitch" />
            )}
            {feedback.volume && (
              <PitchOrVolScoreCard pitchOrVol={pitchOrVol} PorV="volume" />
            )}
          </AIScoreRow>
          <LineChart
            data={filteredChartData}
            withLegend
            yAxisMaxVal={maxGraphYAxisValue}
            unit={""}
          />
        </div>
        <div>
          <h5>Additional Notes</h5>
          <Textarea value={notes} onChange={(e) => setNotes(e.target.value)} />
        </div>
      </ReviewGrid>
      <h3>Exercise Statistics</h3>
      <DataTable data={statistics} columns={stat_columns} />
      <ButtonBar>
        <Button raised onClick={onSave}>
          Save
        </Button>
        <Button
          secondary
          to={`/patients/existing/${patientID}/therapy-plan/edit`}
        >
          Edit Therapy Plan
        </Button>
      </ButtonBar>
    </CardBase>
  );
};

export default ExerciseReview;
