import React from "react";

import Button from "../../components/Button";
import {
  ExcerciseContainer,
  ExcerciseTags,
  FlexRowDiv,
} from "../../GlobalStyles/GlobalStyles";
import { Body } from "../../components/Typography";
import styled from "styled-components";

function ExLibListItem({ title, id, tags }) {
  const StyledButton = styled(Button)`
    border: 2px solid #20365a;
    border-radius: 10px;
    padding: 20px 35px;
    color: green;
  `;

  return (
    <ExcerciseContainer>
      <div>
        <Body>{title}</Body>
        <FlexRowDiv>
          {tags
            ? tags.map((tag) => (
                <ExcerciseTags>
                  <p>{tag}</p>
                </ExcerciseTags>
              ))
            : null}
        </FlexRowDiv>
      </div>
      <StyledButton to={`/exercise-library/exercise/${id}`}>Open</StyledButton>
    </ExcerciseContainer>
  );
}

export default ExLibListItem;
