import React, { useState, useEffect } from "react";

import usePromise from "utils/hooks/usePromise";
import SFPatientsClient from "utils/datastore/patients";

import Button from "../../components/Button";
import ExPlanNotDynamic from "../../components/ExPlanNotDynamic";
import { ExercisePlanContainer } from "../../GlobalStyles/GlobalStyles";

const PatientExerciseWeek = ({ patientID, disabled }) => {
  const patientIds = [];
  const [patient, setPatient] = useState();
  const [exPlan, setExPlan] = useState({});

  const [isPatientsLoading, patients] = usePromise(
    () => SFPatientsClient.fetchPatientsByIds(patientIds),
    []
  );

  const orderPlan = (plan) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const dateNow = new Date();
    const dayToday = dateNow.getDay();

    const startOfWeek = daysOfWeek.splice(dayToday, 7);

    const newWeek = [...startOfWeek, ...daysOfWeek];

    const newPlan = {};

    for (let i = 0; i < newWeek.length; i++) {
      Object.assign(newPlan, { [newWeek[i]]: plan[newWeek[i]] });
    }

    return newPlan;
  };

  useEffect(() => {
    if (patients) {
      const thisPatient = patients.filter((pat) => pat.id === patientID);
      const plan = JSON.parse(thisPatient[0].therapyPlan.exercisePlan);
      const orderedPlan = orderPlan(plan);
      setPatient(thisPatient);
      setExPlan(orderedPlan);
    }
  }, [patients, patientID]);

  return (
    <ExercisePlanContainer>
      {patient ? (
        <>
          <ExPlanNotDynamic exPlan={exPlan} />
          <Button type="button" to={"/exercise-library"}>
            + Assign
          </Button>
        </>
      ) : null}
    </ExercisePlanContainer>
  );
};

export default PatientExerciseWeek;
