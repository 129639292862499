import React from 'react';
import styled from 'styled-components';

const Legend = styled.fieldset`
  display: flex;
  flex-wrap: wrap;
  border: 0;
  color: #A5A3A3;
`;

const LegendLabel = styled.label`
  margin-right: 30px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  & > input {
    display: inline-block;
    opacity: 0.1;
    width: 0;
  }
  & > span::before {
    content: '';
    display: inline-block;
    height: 10px;
    width: 25px;
    border-radius: 2px;
    margin-right: 10px;
    background: #A5A3A3;
  }
  & > input:checked ~ span::before {
    background: ${props => props.colour};
  }
`;

const ChartLegend = ({ data, activeSeries, setSeriesActiveState }) => <Legend>
  {data.map(series => <LegendLabel key={series.name} colour={series.colour}>
    <input type="checkbox" name={series.name} checked={!!activeSeries[series.name]} onChange={event => setSeriesActiveState(series.name, event.target.checked)} />
    <span>{series.name}</span>
  </LegendLabel>)}
</Legend>;

export default ChartLegend;