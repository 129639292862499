import React from "react";

import BackToExerciseDiv from "../../components/BackToExerciseDiv";
import VideoUploadInstructions from "../../components/VideoUploadInstructions";

const VideoInstructions = () => {
  return (
    <>
      <h1>Add New Exercise</h1>
      <BackToExerciseDiv
        linkLocation="/exercise-library/add"
        text="New Exercise"
      />
      <VideoUploadInstructions />
    </>
  );
};

export default VideoInstructions;
