import React from "react";

import example_video from "../../assets/video_example.png";
import CheckCircleAndText from "../../components/CheckCircleAndText";

import {
  MainWhiteContentContainer,
  FlexRowDiv,
  ExampleVideoImage,
} from "../../GlobalStyles/GlobalStyles";
import { Subheading2, Body } from "../../components/Typography";
import Button from "../../components/Button";

function VideoUploadInstructions() {
  return (
    <MainWhiteContentContainer>
      <Subheading2>Video Upload Instructions</Subheading2>
      <Body>
        Simply upload a video of you saying your chosen word as shown in the
        example below:
      </Body>
      <br />
      <FlexRowDiv>
        <ExampleVideoImage src={example_video}></ExampleVideoImage>
        <div>
          <CheckCircleAndText text="Be in a quiet and well lit room" />
          <CheckCircleAndText text="Make sure your mouth can be clearly seen" />
          <CheckCircleAndText text="Your video should start straight away" />
          <CheckCircleAndText text="Your face should take up the majority of the frame" />
          <CheckCircleAndText text="Have your camera on portrait mode" />
          <CheckCircleAndText text="Make sure your file is a mp4" />
        </div>
      </FlexRowDiv>
      <Button primary to={"/exercise-library/add/videophrase"}>
        Next
      </Button>
    </MainWhiteContentContainer>
  );
}

export default VideoUploadInstructions;
