import styled from "styled-components";

import TopBar from "../../components/TopBar";

const TopBarLayoutContainer = styled.div`
  display: flex;
`;

const TopBarLayout = ({ screenName }) => {
  return (
    <TopBarLayoutContainer>
      <TopBar screenName={screenName} />
    </TopBarLayoutContainer>
  );
};

export default TopBarLayout;
