import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MovieOutlinedIcon from "@material-ui/icons/MovieOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

import {
  MainWhiteContentContainer,
  FlexRowDiv,
} from "../../GlobalStyles/GlobalStyles";
import Button from "../../components/Button";
import SFExcerciseClient from "utils/datastore/exercise";
import usePromise from "utils/hooks/usePromise";

const ExLibContainer = ({
  disabled,
  title,
  description,
  categories,
  tags,
  ...props
}) => {
  const { id } = useParams();

  const [setExercise, exercise] = usePromise(
    () => SFExcerciseClient.getExercise(id),
    []
  );

  const FlexRowDivMargBotAndLeft = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-left: 25px;
  `;

  const UnderlinedPara = styled.p`
    border-bottom: 1px solid #e5e5e5;
    width: 300px;
  `;

  const BoxedPara = styled.p`
    border: 1px solid #e5e5e5;
    padding: 10px 5px;
    border-radius: 5px;
    width: 50%;
    min-width: 300px;
  `;

  const ButtonDiv = styled.div`
    display: flex;
    margin-left: 15px;
  `;

  const BoxedTags = styled.div`
    border: 1px solid #e5e5e5;
    padding: 1px 10px;
    border-radius: 5px;
    margin: 5px;
  `;

  const TickBoxDiv = styled.div`
    display: flex;
    align-items: center;
  `;

  const MarginOnPTag = styled.p`
    margin-left: 15px;
  `;

  const [groups, setGroups] = useState();

  useEffect(() => {
    const groupCheck = exercise?.tags;

    if (groupCheck) {
      const groups = JSON.parse(groupCheck);
      setGroups(groups);
    }
  }, []);

  return (
    <>
      <UnderlinedPara>{exercise?.title || ""}</UnderlinedPara>
      <FlexRowDiv>
        <div>
          <p>Exercise Description (for other Therapists)</p>
          <div>
            <BoxedPara>{exercise?.description || ""}</BoxedPara>
          </div>
        </div>
        <FlexRowDivMargBotAndLeft>
          <p>Public Exercise</p>
          <div>{exercise?.public ? <p>Yes</p> : <p>No</p>}</div>
        </FlexRowDivMargBotAndLeft>
      </FlexRowDiv>

      <p>Motor Disorder</p>

      {exercise?.category ? (
        <TickBoxDiv>
          <CheckCircleIcon />
          <MarginOnPTag>{exercise.category}</MarginOnPTag>
        </TickBoxDiv>
      ) : null}

      {exercise?.tags ? <p>Groups</p> : null}
      <FlexRowDiv>
        {exercise?.tags
          ? JSON.parse(exercise.tags).map((gTag) => (
            <BoxedTags>
              <p>{gTag}</p>
            </BoxedTags>
          ))
          : null}
      </FlexRowDiv>

      <p>Word or Phrase</p>
      {exercise?.exerciseSegments.map((exSeg) => {
        const render = JSON.parse(exSeg);
        return <MainWhiteContentContainer>
          <FlexRowDiv>
            <FlexRowDiv>
              <MovieOutlinedIcon />
              <FlexRowDivMargBotAndLeft>{render.videoSegments[0].prompt.prompt}</FlexRowDivMargBotAndLeft>
            </FlexRowDiv>
            {/* <DeleteOutlineOutlinedIcon
            style={{ marginLeft: "auto", marginRight: 0 }}
          /> */}
          </FlexRowDiv>
        </MainWhiteContentContainer>
      })}
      <ButtonDiv>
        <Button to={`/exercise-library/assign/${id}`}>Assign Exercise</Button>
        <Button secondary style={{ marginLeft: "15px" }}>
          Archive
        </Button>
      </ButtonDiv>
    </>
  );
};

export default ExLibContainer;
