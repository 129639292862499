import React, { useContext, useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

import AuthContext from 'state/auth';
import {fetchExistingTherapist} from '../TherapistProfile/utils/submit'
import Profile from "routes/my-profile";


export const PrivateRoute = (props) => {
  const { isLoggedIn, isLoaded } = useContext(AuthContext);

  if (!isLoaded) {
    return null
  } else if (isLoggedIn) {
    return <Route {...props} />
  } else {
    return <Redirect to="/sign-in" />
  }
}

export const ProfileCompleteRoute = (props) => {
  return <PrivateRoute {...props} component={null} render={() => <ProfileCompleteValidator {...props} />} />
}

export const ProfileCompleteValidator = (props) => {
  const [loading, setLoading] = useState(true);
  const [therapist, setTherapist] = useState();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user?.username) {
      fetchExistingTherapist(user.username)
        .then((therapist) => {
          setTherapist(therapist);
          setLoading(false);
        })
        .catch((error) => setLoading(false));
    }
  }, []);

  if (loading) {
    return null; 
  } else {
    if (therapist) {
      return <Route {...props} />;
    } else {
      return <Route component={Profile} />
    }
  }
}