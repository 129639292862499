import SFInviteClient from "utils/datastore/therapists";

export const createTherapist = (values, therapistID) => {
  const newTherapist = {
    email: values.email,
    forename: values.forename,
    surname: values.surname,
    id: therapistID,
  };
  console.log("[INVITE] Sending invite with data....", newTherapist);

  return SFInviteClient.createTherapist(newTherapist);
};

export const updateTherapist = async (therapist, values) => {
  console.log("Updating Therapist");

  const newTherapist = {
    ...therapist,
    ...values,
  };

  await SFInviteClient.updateTherapist(newTherapist);;
};
