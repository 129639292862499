import React, { useContext, useState, useEffect } from "react";
import { Formik } from "formik";

import InviteForm from "components/InviteForm";
import { fetchExistingTherapist } from '../../containers/TherapistProfile/utils/submit'

import { onSubmit } from './utils/submit';

import AuthContext from 'state/auth';

const INITIAL_VALUES = {
  firstName: "",
  lastName: "",
  email: "",
  initial: "",
  therapy: "",
  exercisePlan: {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  }
};

const InviteFormContainer = () => {
  const [loading, setIsLoading] = useState(false);
  const [therapist, setTherapist] = useState();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user?.username) {
      fetchExistingTherapist(user.username).then((therapist) => {
        setTherapist(therapist);
      });
    }
  }, [user.username]);

  console.log('USER: ', user);

  if (!user) {
    return null;
  }

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={async values => {
        setIsLoading(true);
        await onSubmit(values, user, therapist.forename);
        setIsLoading(false);
      }}
    >
      {props => <InviteForm disabled={loading} {...props} />}
    </Formik>
  );
};

export default InviteFormContainer;
