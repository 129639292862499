import React, { useContext, useState, useEffect } from "react";
import TopBar from "../../components/TopBar";
import icon from '../../assets/emptyProfileIcon.svg';
import AuthContext from 'state/auth';
import { fetchExistingTherapist } from '../../containers/TherapistProfile/utils/submit'

import ProfileCard from '../../components/ProfileCard'
import EmptyProfileCard from '../../components/ProfileCard/EmptyProfileCard'

const Profile = () => {

  const [therapist, setTherapist] = useState();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user?.username) {
      fetchExistingTherapist(user.username).then((therapist) => {
        setTherapist(therapist);
      });
    }
  }, [user.username]);

  return (
    <div>
      <TopBar title="My Profile" />
      {therapist ? (
        <ProfileCard
          title={`${therapist.forename} ${therapist.surname}`}
          subTitle={therapist.createdAt}
          email={therapist.email}
          ButtonTxt="Edit"
          imgUrl={icon}
        />
      ) : (
        <EmptyProfileCard
          title="Complete Your Profile"
          subTitle="We just need a few quick details"
          ButtonTxt="Complete Profile"
          imgUrl={icon}
        />
      )}
    </div>
  );
};

export default Profile;
