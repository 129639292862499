import React from 'react';
import styled from 'styled-components';
import { List, CollapsibleList, SimpleListItem } from '@rmwc/list';

import Card from 'components/Card';
import { Body, Label } from 'components/Typography';

const SupportCard = styled(Card)`
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem;
`

const P = styled(Body)`
  margin: 1rem;
`;

const GreyLabel = styled(Label)`
  margin-left: 1rem;
`

const SCollapsibleList = styled(CollapsibleList)`
padding: 1rem 0;
  border-bottom: 1px solid #F5F5F5;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.15px;

  i {
    color: #000000
  }
`

const SupportFAQ = () => <SupportCard>
  <Title>
    <h2>Frequently Asked Questions</h2>
    <GreyLabel>4 Total</GreyLabel>
  </Title>

  <List>
    <SCollapsibleList
      handle={
        <SimpleListItem
          text="How to add or remove patients from dashboard?"
          metaIcon="chevron_right"
        />
      }
    >
      <P>You can add a new patient by clicking on the 'My Patients' tab in the dashboard.</P>
    </SCollapsibleList>

    <SCollapsibleList
      handle={
        <SimpleListItem
          text="How to assign exercises to patients?"
          metaIcon="chevron_right"
        />
      }
    >
      <P>Navigate to 'My Patients'. Within 'My Patient Overview', Select the Patient by clicking on the name. You should be directed a new page which presents an option to 'Assign New Exercise'.</P>
    </SCollapsibleList>

    <SCollapsibleList
      handle={
        <SimpleListItem
          text="What is the AI percentage score based on?"
          metaIcon="chevron_right"
        />
      }
    >
      <ul>
        <li><P>For consonants: Based on how confident the model is that the sound matches the target consonant (e.g. if the model is 87% confident it was an "Ah-pah" sound the score would be 87%).</P></li>
        <li><P>For consonant pairs: Scored on how distinct the paired sounds sound. E.g. if a recording is ah-pah and the model is 95% sure it's ah-pah and 15% sure it's ah-bah, the score would be sq_root([.95]*[1-.15]) = 0.898 or 90% confidence.</P></li>
        <li><P>For words: Scored on the similarity between the phonetic representation of the word and the phonetic representation of the transcription.</P></li>
        <li><P>For phrases: Scored on the similarity between the phonetic representation of the word and the phonetic representation of the transcription.</P></li>
      </ul>
    </SCollapsibleList>

    <SCollapsibleList
      handle={
        <SimpleListItem
          text="Does my review score override the AI score? "
          metaIcon="chevron_right"
        />
      }
    >
      <P>Yes. SpeechFirst's AI provides the analysis you need to make an informed decision which include the patient's recordings, pitch level, volume, speech speed. Using the data provided, you are empowered to provide the final score.</P>
    </SCollapsibleList>
  </List>

</SupportCard>;

export default SupportFAQ;