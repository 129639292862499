import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@rmwc/theme";

import State from "state/";

import Home from "routes/index";
import SignUp from "routes/sign-up";
import SignIn from "routes/sign-in";
import AddPatient from "routes/patients/new";
import Patients from "routes/patients";
import ExistingPatient from "routes/patients/existing";
import PatientSubmissionReview from "routes/patients/existing/submissions";
import NotFound from "routes/404";
import Support from "routes/support";
import ExerciseLibrary from "routes/exercise-library";
import Profile from "routes/my-profile";
import EditProfile from "routes/my-profile/EditProfile";

import SideBarLayout from "layouts/SideBarLayout";
import { PrivateRoute, ProfileCompleteRoute } from "containers/PrivateRoute";
import SnackbarQueue from "containers/SnackbarQueue";

import theme from "constants/theme";
import AddNewExInstructionsPage from "routes/add-new-excercise/AddNewExInstructionsPage";
import AddNewExercisePage from "routes/add-new-excercise/AddNewExercisePage";
import AddWordAndVideo from "routes/add-new-excercise/AddWordAndVideo";
import VideoUploadInstructions from "routes/add-new-excercise/VideoInstructions";
import ShareNewExercisePage from "routes/add-new-excercise/ShareNewExercisePage";
import ExerciseSubmitted from "routes/add-new-excercise/ExerciseSubmitted";
import ViewExercise from "routes/add-new-excercise/ViewExercise";
import AssignExercise from "routes/add-new-excercise/AssignExercise";
import ExerciseAssigned from "routes/add-new-excercise/ExerciseAssigned";

const AppRouter = () => {
  return (
    <ThemeProvider options={theme}>
      <Router>
        <State>
          <>
            <SnackbarQueue />
            <Switch>
              <Route exact path="/sign-up" component={SignUp} />
              <Route exact path="/sign-in" component={SignIn} />
              <PrivateRoute
                path="/"
                render={() => {
                  return (
                    <SideBarLayout>
                      <Switch>
                        <ProfileCompleteRoute exact path="/" component={Home} />
                        <ProfileCompleteRoute
                          exact
                          path="/support"
                          component={Support}
                        />
                        <ProfileCompleteRoute
                          exact
                          path="/exercise-library"
                          component={ExerciseLibrary}
                        />
                        <ProfileCompleteRoute
                          exact
                          path="/exercise-library/instructions"
                          component={AddNewExInstructionsPage}
                        />
                        <ProfileCompleteRoute
                          exact
                          path="/exercise-library/add"
                          component={AddNewExercisePage}
                        />
                        <ProfileCompleteRoute
                          exact
                          path="/exercise-library/add/upload-instructions"
                          component={VideoUploadInstructions}
                        />
                        <ProfileCompleteRoute
                          exact
                          path="/exercise-library/add/videophrase"
                          component={AddWordAndVideo}
                        />
                        <ProfileCompleteRoute
                          exact
                          path="/exercise-library/add/share"
                          component={ShareNewExercisePage}
                        />
                        <ProfileCompleteRoute
                          exact
                          path="/exercise-library/add/submitted"
                          component={ExerciseSubmitted}
                        />
                        <ProfileCompleteRoute
                          exact
                          path="/exercise-library/exercise/:id"
                          component={ViewExercise}
                        />
                        <ProfileCompleteRoute
                          exact
                          path="/exercise-library/assign/:id"
                          component={AssignExercise}
                        />
                        <PrivateRoute
                          exact
                          path="/my-profile"
                          component={Profile}
                        />
                        <PrivateRoute
                          exact
                          path="/my-profile/edit"
                          component={EditProfile}
                        />
                        <ProfileCompleteRoute
                          exact
                          path="/patients"
                          component={Patients}
                        />
                        <ProfileCompleteRoute
                          exact
                          path="/patients/new"
                          component={AddPatient}
                        />
                        <ProfileCompleteRoute
                          exact
                          path="/patients/existing/:id"
                          component={ExistingPatient}
                        />
                        <ProfileCompleteRoute
                          exact
                          path="/patients/existing/:patientID/submissions/:submissionID"
                          component={PatientSubmissionReview}
                        />
                        <ProfileCompleteRoute
                          exact
                          path="/exercise-library/assigned"
                          component={ExerciseAssigned}
                        />

                        <Route path="*" component={NotFound} />
                      </Switch>
                    </SideBarLayout>
                  );
                }}
              />
            </Switch>
          </>
        </State>
      </Router>
    </ThemeProvider>
  );
};

export default AppRouter;
