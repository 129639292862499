import React from 'react';
import { Form } from "formik";
import TextField from "@material-ui/core/TextField";
import styled from 'styled-components';

import emptyProfileIcon from '../../assets/emptyProfileIcon.svg'
import { Row, FlexRow } from "../Containers";
import Button from "../Button";

const TherapistProfileForm = ({ disabled, forename, surname, email, ...props }) => {
  const StyledButton = styled(Button)`
    margin-left: 20px;
  `;

  const StyledRow = styled(Row)`
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  `;

  return (
    <Form>
      <StyledRow>
        <img src={emptyProfileIcon} alt="" />
        <StyledButton secondary disabled={disabled}>
          Change
        </StyledButton>
      </StyledRow>
      <FlexRow>
        <TextField
          id="forename"
          name="forename"
          label="First Name"
          placeholder={forename}
          disabled={disabled}
          value={props.values.forename}
          onChange={props.handleChange("forename")}
          onBlur={props.handleChange("forename")}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ marginTop: 10, marginRight: 100, width: 300 }}
        />
        <TextField
          id="surname"
          name="surname"
          label="Last Name"
          disabled={disabled}
          value={props.values.surname}
          onChange={props.handleChange("surname")}
          onBlur={props.handleChange("surname")}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ marginTop: 10, marginRight: 100, width: 300 }}
        />
      </FlexRow>
      <Row>
        <TextField
          id="email"
          name="email"
          label="Email"
          disabled={disabled}
          value={props.values.email}
          onChange={props.handleChange("email")}
          onBlur={props.handleChange("email")}
          style={{ marginRight: 10, width: 300 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Row>
      <Row>
        <TextField
          multiline
          fullWidth
          id="password"
          name="password"
          label="Password"
          disabled={disabled}
          value={props.values.initial}
          onChange={props.handleChange("password")}
          onBlur={props.handleChange("password")}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ width: 300 }}
        />
        <Button secondary disabled={disabled}>
          Reset
        </Button>
      </Row>

      <Button type="submit">Submit</Button>
    </Form>
  );
};

export default TherapistProfileForm;