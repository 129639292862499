import React, { useMemo, useContext } from 'react';
import styled from 'styled-components';

import DataTable, { TableHeader } from 'components/DataTable';
import Card from 'components/Card';
import PatientStatusChip from 'components/PatientStatusChip';

import AuthContext from 'state/auth';

import usePromise from 'utils/hooks/usePromise';

import SFFeedbackClient from 'utils/datastore/exercise/feedback';
import SFPatientsClient from 'utils/datastore/patients';

const TableCard = styled(Card)`
`;

const columns = [
  {
    name: "Name",
    key: "name",
    weight: 20
  },
  {
    name: "Joined",
    key: "joined",
    weight: 16
  },
  {
    name: <span>Used app in <br />the last 24h</span>,
    key: "hasUsed24",
    weight: 16
  },
  {
    name: <span>No. of exercises <br />completed this week</span>,
    key: "completedThisWeek",
    weight: 17
  },
  {
    name: <span>No. of exercises not <br />completed this week</span>,
    key: "notCompletedThisWeek",
    weight: 17
  },
  {
    name: <span>Overall <br />Performance</span>,
    key: "performance",
    weight: 14
  }
];

const options = { month: 'short', year: 'numeric' };
const dateFormatter = new Intl.DateTimeFormat('en-GB', options);

const formatResultForTable = (results = []) => {
  return results
    .map(patient => ({
      href: "/patients/existing/" + patient.id,
      data: {
        name: `${patient.forename} ${patient.surname}`,
        joined: dateFormatter.format(patient.joinedDate),
        hasUsed24: patient.usedInLast24Hrs ? "Yes" : "No",
        completedThisWeek: patient.exercisesCompletedThisWeek,
        notCompletedThisWeek: 0,
        performance: patient.score <= 0.65 ? <PatientStatusChip.NeedsSupport /> : <PatientStatusChip.Good />
      }
    }));
}

const PatientsPendingHelpTable = () => {
  const { user } = useContext(AuthContext);

  const therapistID = useMemo(() => user && user.attributes.sub, [user && user.attributes.sub]);

  const [isLoading, result] = usePatientsPendingHelpData(therapistID);

  const tableData = useMemo(() => formatResultForTable(result), [result]);


  if (isLoading) {
    return null;
  }

  return (<TableCard>
    <TableHeader>
      <h4>Patients Needing Support <span className="light">{tableData.length} Total</span></h4>
    </TableHeader>
    <DataTable data={tableData} columns={columns} />
  </TableCard>);
}

function getMonday(d) {
  d = new Date(d);
  const day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(d.setTime(diff));
}

function getLast24(d) {
  return new Date(d.setTime(d.getTime() - 24 * 60 * 60 * 1000));
}


const formatInputsToData = (feedbackPending = [], patients = []) => {

  const startOfWeek = getMonday(new Date(Date.now()));
  const last24 = getLast24(new Date(Date.now()));

  console.log(patients);

  if (!patients || !feedbackPending) {
    return [];
  }

  return patients
    .map(patient => ({
      ...patient,
      submissions: feedbackPending.filter(feedback => feedback.patientID === patient.id)
        .map(sub => ({
          ...sub,
          date: new Date(sub.createdAt)
        })),
    }))
    .map(patient => ({
      ...patient,
      score: patient.submissions.reduce((score, submission) => score + (submission.score || 0), 0) / patient.submissions.filter(sub => sub.score >= 0).length,
      exercisesCompletedThisWeek: patient.submissions.filter(sub => sub.date >= startOfWeek).length,
      joinedDate: new Date(patient.createdAt),
      usedInLast24Hrs: patient.submissions.filter(sub => sub.date >= last24).length > 0
    }));
}

const usePatientsPendingHelpData = (therapistID) => {
  const [isFeedbackLoading, feedbackPending] = usePromise(() => SFFeedbackClient.fetchPendingFeedback(therapistID), [therapistID]);

  const patientIds = [];

  const [isPatientsLoading, patients] = usePromise(() => SFPatientsClient.fetchPatientsByIds(patientIds), []);

  const data = useMemo(() => formatInputsToData(feedbackPending, patients), [feedbackPending, patients])

  return [isFeedbackLoading || isPatientsLoading, data];
}

export default PatientsPendingHelpTable;