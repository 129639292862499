import React from "react";
import { GreyTextBox, WhiteTextBox } from "../../GlobalStyles/GlobalStyles";

function TabMaker({ tab, tabName, fullTabName }) {
  if (tab === tabName) {
    return (
      <GreyTextBox>
        <h4>{fullTabName}</h4>
      </GreyTextBox>
    );
  } else {
    return (
      <WhiteTextBox>
        <h4>{fullTabName}</h4>
      </WhiteTextBox>
    );
  }
}

export default TabMaker;
