import React from "react";

import {
  AddNewExCont,
  FlexColSpacedEvenDiv,
} from "../../GlobalStyles/GlobalStyles";
import { Subheading2 } from "../../components/Typography";

import uploadIcon from "../../assets/uploadIcon.svg";
import Button from "../../components/Button";

function AddNewExcercise() {
  return (
    <AddNewExCont>
      <Subheading2>Add new exercise</Subheading2>
      <FlexColSpacedEvenDiv>
        <img src={uploadIcon} alt="upload icon" />
        <Button to={"/exercise-library/instructions"}>+ Add</Button>
      </FlexColSpacedEvenDiv>
    </AddNewExCont>
  );
}

export default AddNewExcercise;
