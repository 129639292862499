import React from "react";

import { AuthProvider } from "./auth";
import { ExerciseProvider } from './exercise'

const StateProviders = ({ children }) => (
  <AuthProvider>
    <ExerciseProvider>
      {children}
    </ExerciseProvider>
  </AuthProvider>
);

export default StateProviders;
