import React from 'react';
import styled from 'styled-components';

const PADDING = {
  regular: "0.25rem 1rem",
  large: "0.5rem 1rem"
}
const FONT_SIZE = {
  regular: "0.6rem",
  large: "1rem"
}

const PatientStatusChip = styled.span`
  padding: ${props => props.large ? PADDING.large : PADDING.regular};
  background: ${props => props.good ? "#6DE4B9" : "#FDBF5E"};
  border-radius: 5px;
  font-size: ${props => props.large ? FONT_SIZE.large : FONT_SIZE.regular};
  text-align: center;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
`;

const PatientStatus = ({ status, ...props }) => {
  return <PatientStatusChip {...props}>{status}</PatientStatusChip>
}

const StatusOptions = {
  Good: (props) => <PatientStatus good status="Good" {...props} />,
  NeedsSupport: (props) => <PatientStatus warn status="Needs Support" {...props} />,
}

export default StatusOptions;