import styled from "styled-components";

export const Body = styled.p`
  font-size: ${(props) => (props.small ? "13px" : "16px")};
  letter-spacing: ${(props) => (props.small ? "0.25px" : "0.5px")};
  font-weight: ${(props) => (props.bold ? "600" : "400")};
  margin: 0;
`;

export const Body2 = styled(Body)`
  color: #979797;
`;

export const Label = styled.label`
  font-size: 16px;
  color: #a5a3a3;
  letter-spacing: 0.15px;
  font-weight: 400;
  margin: 0;
`;

export const BlackLabel = styled.label`
  font-size: 16px;
  letter-spacing: 0.15px;
  font-weight: 400;
  margin: 0;
`;

export const ButtonText = styled.p`
  font-size: 14px;
  letter-spacing: 1.25px;
  font-weight: 400;
  margin: 0;
`;

export const H1 = styled.h1`
  font-size: ${(props) => (props.subheader ? "23px" : "93px")};
  letter-spacing: ${(props) => (props.subheader ? "0px" : "-1.5px")};
  font-weight: ${(props) => (props.subheader ? 600 : 300)};
  margin: 0;
`;

export const H2 = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
`;

export const H3 = styled.h3`
  font-size: 1em;
  font-weight: 600;
  margin: 0;
`;

export const H4 = styled.h4`
  font-size: 33px;
  letter-spacing: 0.25px;
  font-weight: 300;
  margin: 0;
`;

export const Subheading = styled.label`
  font-size: 16px;
  letter-spacing: 0.15px;
  font-weight: 600;
`;

export const Subheading2 = styled.p`
  font-size: ${(props) => (props.small ? "16px" : "19.7px")};
  letter-spacing: ${(props) => (props.small ? "0.15px" : "0.25px")};
  font-weight: ${(props) => (props.bold ? "600" : "400")};
  margin: 0;
`;
