import SFExerciseClient from "utils/datastore/exercise";

export const createExercise = (values) => {
  const strTags = JSON.stringify(values.tags);

  const newExercise = {
    title: values.title,
    description: values.description,
    category: values.category,
    exerciseSegments: values.exerciseSegments.map(v => JSON.stringify(v)),
    tags: `${strTags}`,
    public: values.isPublic,
  };

  return SFExerciseClient.createExercise(newExercise);
};
