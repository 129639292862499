import { useMemo } from 'react';

import PatientStatusChip from 'components/PatientStatusChip';

import usePromise from 'utils/hooks/usePromise';

import SFSubmissionClient from 'utils/datastore/exercise/submission';
import SFFeedbackClient from 'utils/datastore/exercise/feedback';

import { zeroPrefix } from 'utils/date';

const MOODS = ["😩", "😞", "😌", "🙂", "😁"];

const useExerciseStatistics = (submissionID) => {
  const [loadingSubmission, submission] = usePromise(() => SFSubmissionClient.fetchSubmission(submissionID), []);
  const feedbackID = useMemo(() => submission && submission.exerciseFeedbackID, [submission]);

  const [loadingFeedback, feedback] = usePromise(() => feedbackID ? SFFeedbackClient.fetchFeedback(feedbackID) : Promise.resolve(null), [feedbackID]);

  if (!feedback || !submission) {
    return [];
  }

  const submissionDate = new Date(submission.createdAt);

  const durationMins = (new Date(submission.ended) - new Date(submission.started)) / (60 * 1000);

  const stats = {
    date: `${zeroPrefix(submissionDate.getDate())}/${zeroPrefix(submissionDate.getMonth() + 1)}/${submissionDate.getFullYear().toString().substr(-2)}`,
    duration: `${Math.round(durationMins)} min`,
    score: `${Math.round(feedback.score * 100)}%`,
    mood: MOODS[submission.mood - 1],
    status: feedback.score <= 0.65 ? <PatientStatusChip.NeedsSupport /> : <PatientStatusChip.Good />,
    repeat: "No",
    selfAssessment: submission.selfAssessment ? `${Math.round((submission.selfAssessment + 1) * 10)}%` : "n/a",
    reviewedScore: feedback.reviewedScore ? `${Math.round(feedback.reviewedScore * 100)}%` : null
  };
  return [
    {
      data: stats
    }
  ];
}

export default useExerciseStatistics;