import { InviteStatus } from "models";

import { queue as messageQueue } from "utils/snackbar";

import SFInviteClient from "utils/datastore/invite";

import { sendInviteWithValues, updateInviteWithValues, resendInvite } from './crud';

export const onSubmit = async (values, user, therapistName) => {
  try {
    const therapistID = user && user.attributes.sub;
    const existingInvite = await fetchExistingInviteForValues(values, therapistID);

    console.log(existingInvite);
    if (!existingInvite) {
      await sendInviteWithValues(values, therapistID, therapistName);
      console.log("Invite saved!");
      messageQueue.notify({
        title: <b>Invite sent.</b>,
        timeout: 10000,
        body:
          "This patient will have the option to accept or reject your invitation.",
      });
    } else {
      await handleExistingInviteWithValues(existingInvite, values);
    }
  } catch (error) {
    console.log("Error saving post", error);
  }
}

const fetchExistingInviteForValues = async (values, therapistID) => {
  const existingInvite = await SFInviteClient.fetchExistingInviteForValues(values.email, therapistID);
  console.debug("Existing invites found with value.... ", existingInvite)
  return existingInvite;
}

const handleExistingInviteWithValues = (invite, values) => {
  return new Promise(resolve => {
    if (invite.status === InviteStatus.PENDING) {
      messageQueue.notify({
        title: <b>You've already invited this patient</b>,
        body:
          "You've already issued an invite to this patient, would you like to update it?",
        dismissesOnAction: true,
        timeout: -1,
        actions: [
          {
            title: "Update",
            icon: "check",
            onClick: () => updateInviteWithValues(invite, values)
              .then(resolve)
              .then(() => {
                messageQueue.notify({
                  title: <b>Invite updated.</b>,
                  timeout: 10000,
                  body:
                    "This patient will have the option to accept or reject your invitation.",
                });
              })
              .catch(resolve),
          },
          {
            label: "Skip",
            icon: "close",
            onClick: resolve
          },
        ],
      });
    } else if (invite.status === InviteStatus.REJECTED) {
      messageQueue.notify({
        title: <b>Invite was previously rejected</b>,
        body:
          "This patient has rejected your invite. Would you like to resend it?",
        dismissesOnAction: true,
        timeout: -1,
        actions: [
          {
            title: "Re-Send",
            icon: "check",
            onClick: () => resendInvite(invite)
              .then(resolve)
              .then(() => {
                messageQueue.notify({
                  title: <b>Invite resent.</b>,
                  timeout: 10000,
                  body:
                    "This patient will have the option to accept or reject your invitation.",
                });
              })
              .catch(resolve),
          },
          {
            label: "Skip",
            icon: "close",
            onClick: resolve
          },
        ],
      });
    } else {
      messageQueue.notify({
        title: <b>This patient has already accepted your invite.</b>,
        body:
          "This patient is already associated with you as a therapist. No further action needed.",
      });
      resolve();
    }
  })
}