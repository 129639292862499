import { API } from "aws-amplify";
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';

import SFTherapyPlanClient from "utils/datastore/therapyPlan";

export default new (class {
  async fetchExistingInviteForValues(email, therapistID) {
    const variables = {
      filter: {
        email: {
          eq: email
        },
        therapistID: {
          eq: therapistID
        }
      },
      $limit: 1
    }
    const result = await API.graphql({ query: queries.listInvites, variables });
    return result?.data?.listInvites?.items && result?.data?.listInvites?.items[0];
  }
  async createInvite(invite) {
    let therapyPlanID;
    if (invite.therapyPlan) {
      if (!invite.therapyPlan.id) {
        const therapyPlan = await SFTherapyPlanClient.createTherapyPlan(invite.therapyPlan);
        therapyPlanID = therapyPlan.id;
        console.log("Created therapy plan with ID ", therapyPlanID, therapyPlan);
      } else {
        therapyPlanID = invite.therapyPlan.id;
      }
    }
    const variables = {
      input: {
        ...invite,
        inviteTherapyPlanId: therapyPlanID
      }
    }
    delete variables.input.therapyPlan;
    console.log("Sending graphql invite creation with vars: ", variables);
    const result = await API.graphql({ query: mutations.createInvite, variables });
    return result?.data?.createInvite;
  }
  async updateInvite(invite) {
    console.log("Invite - called update with param....", invite);
    if (invite.therapyPlan) {
      if (invite.therapyPlan.id) {
        await SFTherapyPlanClient.updateTherapyPlan(invite.therapyPlan);
        console.log("Updated therapy plan with ID ", invite.therapyPlan);
      }
    }
    const variables = {
      input: {
        ...invite,
        inviteTherapyPlanId: invite.therapyPlan.id
      }
    }
    delete variables.input._deleted;
    delete variables.input._lastChangedAt;
    delete variables.input.updatedAt;
    delete variables.input.createdAt;
    delete variables.input.therapyPlan;
    console.log("Sending graphql invite update with vars: ", variables);
    const result = await API.graphql({ query: mutations.updateInvite, variables });
    return result?.data?.updateInvite;
  }
})();