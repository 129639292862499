import { useMemo } from "react";

import usePromise from "utils/hooks/usePromise";

import SFSubmissionClient from "utils/datastore/exercise/submission";
import SFFeedbackClient from "utils/datastore/exercise/feedback";

const useExerciseFeedback = (submissionID) => {
  const [loadingSubmission, submission] = usePromise(
    () => SFSubmissionClient.fetchSubmission(submissionID),
    []
  );

  const feedbackID = useMemo(
    () => submission && submission.exerciseFeedbackID,
    [submission]
  );

  const [loadingFeedback, feedback] = usePromise(
    () =>
      feedbackID
        ? SFFeedbackClient.fetchFeedback(feedbackID)
        : Promise.resolve(null),
    [feedbackID]
  );

  if (feedback) {
    return feedback;
  }

  if (submission) {
    return submission;
  }

  if (!feedback || !submission) {
    return [];
  }
};

export default useExerciseFeedback;
