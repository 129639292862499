import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { FlexColCenteredDiv } from "../../GlobalStyles/GlobalStyles";
import { Link } from "react-router-dom";

const BackToExerciseDiv = ({ linkLocation, text }) => (
  <Link to={linkLocation}>
    <FlexColCenteredDiv>
      <ArrowBackIcon />
      <p>Back to {text}</p>
    </FlexColCenteredDiv>
  </Link>
);

export default BackToExerciseDiv;
