import React, { useEffect, useState } from "react";
import { Formik, useFormikContext } from "formik";
import { onSubmit } from "./utils/submit";
import AddNewExcerciseForm from "../../components/AddNewExcerciseForm";
import { CircularSpinner } from "components/Loading";
import { useExercise } from "state/exercise/hooks";
import * as yup from "yup";

const schema = yup.object({
  title: yup.string(),
  description: yup.string(),
  category: yup.string()
})

const Update = () => {
  // Grab values and update context
  const { values } = useFormikContext();
  const { updateValues } = useExercise();
  useEffect(() => {
    updateValues(values)
  }, [updateValues, values]);
  return null;
};

const AddNewExerciseContainer = ({ push }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { formValues, updateValues, resetValues } = useExercise();
  console.log('values: ', formValues);


  return (<>
    {isLoading && <CircularSpinner size="xlarge" />}
    {!isLoading && <Formik
      initialValues={formValues}
      validationSchema={schema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(false);
        if (values.exerciseSegments.length === 0) {
          alert('Please add at least one word or phrase');
        } else {
          setIsLoading(true);
          await onSubmit(values);
          resetValues();
          push("/exercise-library/add/submitted");
          setIsLoading(false);
        }
      }}
      style={{ maxWidth: "100%" }}
    >
      {(props) => (<div>
        <AddNewExcerciseForm
          disabled={isLoading}
          {...props}
          updateValues={updateValues}
          title={"title"}
          description={"description"}
          category={"category"}
          tags={"tags"}
          isPublic={"isPublic"}
        />
        <Update />
      </div>
      )}
    </Formik>}
  </>);
};

export default AddNewExerciseContainer;
