import React from 'react';
import { DataTable, DataTableContent, DataTableHead, DataTableBody, DataTableCell, DataTableHeadCell, DataTableRow } from '@rmwc/data-table';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import '@rmwc/data-table/styles';

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  margin-bottom: 1.5rem;

  & > * {
    margin: 0;
    line-height: 0.9;
  }
  & .light {
    font-weight: normal;
    margin-left: 1.5rem;
    color: #A5A3A3;
  }
`;

const TableContainer = styled.div`
  table {
    table-layout: fixed;
    width: 100%;
  }
  th {
    word-wrap: break-word;
    font-size: 0.7rem;
    line-height: 1.3;
  }
  td, th {
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  td {
    padding: 1.5rem 1rem;
  }
  .mdc-data-table, .mdc-data-table__content {
    border: 0;
  }
  .rmwc-data-table__head {
    background: #F5F5F5;
  }
  .mdc-data-table__row:first-child {
    border-top: none;
  }
  a {
    text-decoration: none;
    color: #000;
  }
`;

const SFDataTable = ({ data, columns }) => {
  const totalCharsInCols = columns.reduce((count, col) => count + col.name.length, 0);
  
  return (<TableContainer>
    <DataTable>
      <DataTableContent>
        <DataTableHead>
          <DataTableRow>
            {columns.map(col => <DataTableHeadCell key={col.key} {...col} style={{ width: (col.weight || Math.floor(col.name.length / totalCharsInCols * 100)) + '%'}}>{col.name}</DataTableHeadCell>)}
          </DataTableRow>
        </DataTableHead>
        <DataTableBody>
          {data.map((row, index) => <DataTableRow {...row} key={index}>
            {columns.map(col => <DataTableCell key={col.key} {...(col.dataAttrs || {})}>{row.href ? <Link to={row.href}>
              {row.data[col.key]}
            </Link> : row.data[col.key]}</DataTableCell>)}
          </DataTableRow>)}
        </DataTableBody>
      </DataTableContent>
    </DataTable>
  </TableContainer>);
}

export default SFDataTable;