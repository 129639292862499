import React from "react";
import TopBar from "components/TopBar";
import SupportFAQ from "containers/SupportFAQ";

const Support = () => {
  return (
    <div>
      <TopBar title="Support" />
      <SupportFAQ />
    </div>
  );
};

export default Support;

