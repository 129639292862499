import React, { useMemo } from 'react';
import styled from 'styled-components';

import Card from 'components/Card';

import usePromise from 'utils/hooks/usePromise';

import SFFeedbackClient from 'utils/datastore/exercise/feedback';
import SFPatientClient from 'utils/datastore/patients';

import { days } from 'utils/date';

const StatsRow = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 1rem;
  margin: 1rem 0;
`;

const StatCard = styled(Card)`
  box-shadow: none;
  border: 1px solid #E5E5E5;
  flex: 1;
  min-width: 100px;
  max-width: 150px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0.5rem 1rem;
  min-height: 4rem;

  & > * {
    margin: 0;
  }
  & h5 {
    font-size: 2rem;
  }
  & p {
    flex: 1;
    font-size: 0.75rem;
    margin-left: 1.5rem;
  }
`;

const PatientExerciseStatsRow = ({ patientID }) => {
  const [loading, stats] = usePatientsData(patientID);

  if (loading) {
    return null;
  }

  return (<StatsRow>
    {stats.map(stat => <StatCard key={stat.description}>
      <h5>{stat.number}</h5>
      <p>{stat.description}</p>
    </StatCard>)}
  </StatsRow>);
}

export default PatientExerciseStatsRow;


const usePatientsData = (patientID) => {
  const lastWeek = useMemo(() => getMonday(new Date()), []);

  const [isFeedbackLoading, feedback] = usePromise(() => SFFeedbackClient.fetchFeedbackFromDate(patientID, lastWeek), [patientID]);

  const [isLoadingPatient, patient] = usePromise(() => SFPatientClient.getPatient(patientID), [patientID]);

  const stats = useMemo(() => formatInputsToStats(feedback, patient), [feedback, patient])

  return [isFeedbackLoading || isLoadingPatient, stats];
}

const formatInputsToStats = (feedback = [], patient = []) => {

  if (!feedback || !patient) {
    return [];
  }

  const enhancedFeedback = feedback
    .map(feedback => ({
      ...feedback,
      startedDate: new Date(feedback.exerciseSubmission.started),
      duration: (new Date(feedback.exerciseSubmission.ended) - new Date(feedback.exerciseSubmission.started)) / (60 * 1000)
    }));

  console.log(feedback, patient);

  const totalDuration = enhancedFeedback.reduce((sum, sub) => sum + sub.duration, 0);

  const exercisePlan = JSON.parse(patient.therapyPlan.exercisePlan);
  
  const todayString = days[new Date().getDay()];

  const assignedToday = exercisePlan[todayString];

  const todayFeedback = enhancedFeedback
    .filter(isToday(getToday()));

  const notStarted = assignedToday
    .filter(ex => !todayFeedback.find(fb => fb.exerciseID === ex.id));

  const upcoming = 
    Object.entries(exercisePlan)
      .filter(([k]) => days.indexOf(k) > (new Date().getDay()));
  
  return [
    {
      number: Math.max(5, totalDuration >= 6 ? (totalDuration / 60).toFixed(1) : Math.round(totalDuration)),
      description: `${totalDuration >= 6 ? "Hours" : "Minutes"} spent on the app this week`
    },
    {
      number: feedback.length,
      description: "Exercises completed this week"
    },
    {
      number: notStarted.length,
      description: "Exercise not started"
    },
    {
      number: feedback.filter(f => !f.hasBeenReviewed).length,
      description: "Exercises to review"
    },
    {
      number: upcoming.length,
      description: "Upcoming exercises"
    }
  ];
}

const getToday = () => {
  const t = new Date();
  t.setHours(0,0,0,0);
  return t;
}
const isToday = today => row => {
  return row.startedDate >= today;
}

function getMonday(d) {
  d = new Date(d);
  const day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6:1);
  return new Date(d.setTime(diff));
}
