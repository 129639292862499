import React, { useMemo } from 'react';
import styled from 'styled-components';

import Card from 'components/Card';
import PatientStatusChip from 'components/PatientStatusChip';

import usePromise from 'utils/hooks/usePromise';

import SFFeedbackClient from 'utils/datastore/exercise/feedback';

import { getMonday } from 'utils/date';

const PerfCard = styled(Card)`
 & h3 {
  margin-top: 0;
 }
 & > :last-child {
   max-width: 80%;
   margin: 0 auto;
   display: block;
 }
`;

const PatientOverallPerfCard = ({ patientID }) => {
  const [loading, score] = usePatientAvgScore(patientID);

  if (loading) {
    return null;
  }

  return <PerfCard>
    <h3>Weekly Performance</h3>
    {score <= 0.65 ? <PatientStatusChip.NeedsSupport large /> : <PatientStatusChip.Good large />}
  </PerfCard>;
};

export default PatientOverallPerfCard;



const usePatientAvgScore = (patientID) => {
  const lastWeek = useMemo(() => getMonday(new Date()), []);

  const [isFeedbackLoading, feedback] = usePromise(() => SFFeedbackClient.fetchFeedbackFromDate(patientID, lastWeek), [patientID]);

  const scoredFeedback = (feedback || []).filter(feedback => feedback.score >= 0);

  const score = scoredFeedback.reduce((count, feedback) => count + feedback.score, 0) / scoredFeedback.length;

  return [isFeedbackLoading || !scoredFeedback.length, score];
}