import React, { useMemo, useContext } from 'react';
import styled from 'styled-components';

import StatsCard from "components/StatCard";

import AuthContext from 'state/auth';

import usePromise from 'utils/hooks/usePromise';

import SFFeedbackClient from 'utils/datastore/exercise/feedback';
import SFPatientsClient from 'utils/datastore/patients';

const StatsGrid = styled.section`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const HomeStatsRow = () => {
  const { user } = useContext(AuthContext);

  const therapistID = useMemo(() => user && user.attributes.sub, [user && user.attributes.sub]);

  const [isLoading, stats] = useStats(therapistID);

  if (isLoading) {
    return null;
  }

  return (<StatsGrid>
    {stats.map(stat => <StatsCard key={stat.title} {...stat} />)}
  </StatsGrid>);
}

function getLast24(d) {
  return new Date(d.setTime(d.getTime() - 24 * 60 * 60 * 1000));
}


const formatInputsToData = (feedbackPending = [], patients = []) => {

  const last24 = getLast24(new Date(Date.now()));

  if (!patients || !feedbackPending) {
    return [];
  }

  return patients
    .map(patient => ({
      ...patient,
      submissions: feedbackPending.filter(feedback => feedback.patientID === patient.id)
        .map(sub => ({
          ...sub,
          date: new Date(sub.createdAt)
        })),
    }))
    .map(patient => ({
      ...patient,
      score: patient.submissions.reduce((score, submission) => score + (submission.score || 0), 0) / patient.submissions.filter(sub => sub.score >= 0).length,
      usedInLast24Hrs: patient.submissions.filter(sub => sub.date >= last24).length > 0
    }));
}

const formatStatsFromData = (data = []) => {
  return [
    {
      stat: 10,
      title: "Patients",
      description: "Have joined the app since Dec 2020"
    },
    {
      stat: data.reduce((count, d) => count + (d.usedInLast24Hrs ? 1 : 0), 0),
      title: "Patients Active",
      description: "On the app in the last 24 hours"
    },
    {
      stat: data.reduce((count, d) => count + (d.score <= 0.65 ? 1 : 0), 0),
      title: "Patients Need Support",
      description: "Last updated today",
      highlight: true,
      flex: true
    }
  ];
}

const useStats = (therapistID) => {
  const [isFeedbackLoading, feedbackPending] = usePromise(() => SFFeedbackClient.fetchPendingFeedback(therapistID), [therapistID]);

  const patientIds = [];

  const [isPatientsLoading, patients] = usePromise(() => SFPatientsClient.fetchPatientsByIds(patientIds), []);

  const data = useMemo(() => formatInputsToData(feedbackPending, patients), [feedbackPending, patients]);

  const stats = useMemo(() => formatStatsFromData(data), [data]);

  return [isFeedbackLoading || isPatientsLoading, stats];
}

export default HomeStatsRow;