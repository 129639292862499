import { createExercise } from "./crud";

export const onSubmit = async (values) => {
  console.log("In the onSubmit");
  try {
    await createExercise(values);
    console.log("Exercise created");
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
  }
};
