import React from 'react';
import styled from 'styled-components';

import Card from 'components/Card';

import icon from './icon.svg';
import Button from 'components/Button';

export const PatientCardBase = styled(Card)`
  display: flex;
  align-items: center;
  text-align: start;
  justify-content: space-between;
  font-size: 0.8rem;
  flex: 3;
  min-width: 13rem;
  padding: 1.5rem 3rem;

  & > div > * {
    margin: 0.25rem;
  }
  & > div > p {
    margin-bottom: 1rem;
  }
  & > div a {
    display: inline-block;
  }
`;

const StatisticsCard = () => <PatientCardBase>
  <img src={icon} alt="Patient Icon" />
  <div>
    <h2>Add New Patient</h2>
    <p>Set up a new profile for your patient</p>
    <Button to="/patients/new" raised>New Profile</Button>
  </div>
</PatientCardBase>

export default StatisticsCard;