import React, {useContext, useState, useEffect} from "react";
import styled from "styled-components";
import AuthContext from 'state/auth';
import {fetchExistingTherapist} from '../../containers/TherapistProfile/utils/submit'

import { BigContainer } from "components/Containers";
import { H2 } from "components/Typography";
import TopBar from "components/TopBar";
import TherapistProfileContainer from "containers/TherapistProfile";

const EditProfile = () => {
  const Content = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    box-sizing: border-box;
    padding: 1em;
    padding-top: 0px;
  `;

  const [therapist, setTherapist] = useState();
  const { user } = useContext(AuthContext);
  const username = user && user.username;

  useEffect(() => {
    if (username) {
      fetchExistingTherapist(username).then((therapist) => {
        setTherapist(therapist);
      });
    }
  }, []);

  return (
    <>
      <TopBar title="Edit My Profile" />
      <Content>
        <BigContainer>
          <H2 subheader>My Profile</H2>
          <TherapistProfileContainer
            forename={therapist?.forename}
            surname={therapist?.surname}
            email={therapist?.email}
          />
        </BigContainer>
      </Content>
    </>
  );
};

export default EditProfile;
