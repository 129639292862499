import { API } from "aws-amplify";
import * as queries from "graphql/queries";

export default new (class {
  async fetchSubmission(submissionID) {
    const variables = {
      id: submissionID,
    };
    const result = await API.graphql({
      query: queries.getExerciseSubmission,
      variables,
    });
    return result?.data?.getExerciseSubmission;
  }
})();
