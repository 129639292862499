import { API } from "aws-amplify";
import * as queries from "graphql/queries";
import * as mutations from "graphql/mutations";

export default new (class {
  async fetchExercisesByIds(ids) {
    const filter = {
      or: ids.map((id) => ({
        id: {
          eq: id,
        },
      })),
    };

    const variables = {
      filter: filter,
      $limit: ids.length,
    };
    const result = await API.graphql({
      query: queries.listExercises,
      variables,
    });
    return (
      result?.data?.listExercises?.items && result?.data?.listExercises?.items
    );
  }
  async getExercise(id) {
    const variables = {
      id,
    };
    const result = await API.graphql({ query: queries.getExercise, variables });
    return result?.data?.getExercise;
  }
  async fetchAllExcercises() {
    const variables = {
      __typename: "Excercise",
    };
    const result = await API.graphql({
      query: queries.listExercises,
      variables,
    });
    return (
      result?.data?.listExercises?.items && result?.data?.listExercises?.items
    );
  }
  async createExercise(exerciseData) {
    console.log("Creating Exercise");

    const variables = {
      input: {
        ...exerciseData,
      },
    };

    const result = await API.graphql({
      query: mutations.createExercise,
      variables,
    });

    return result;
  }
})();
