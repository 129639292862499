import styled from "styled-components";

import SideBar from "components/SideBar";

const SideBarLayoutContainer = styled.div`
  display: flex;
`;

const MainContent = styled.div`
  flex: 1;
  box-sizing: border-box;
  padding: 30px;
  padding-left: 290px;
  background-color: #f7f7f7;
  min-height: 100vh;
`;

const SideBarLayout = ({ children }) => {
  return (
    <SideBarLayoutContainer>
      <SideBar />
      <MainContent>
        {children}
      </MainContent>
    </SideBarLayoutContainer>
  );
};

export default SideBarLayout;
