import React from 'react';
import styled from 'styled-components';

import Card from 'components/Card';

export const StatCardBase = styled(Card)`
  text-align: center;
  font-size: 0.8rem;
  padding: 1.5rem 1rem;

  & h2 {
    font-weight: 600;
    font-size: 4rem;
    line-height: 1;
  }
  & > * {
    margin: 0.25rem;
  }
  flex: ${props => props.flex ? 3 : 2};
  min-width: 13rem;
`;

const StatisticsCard = ({ highlight, stat, title, description, flex }) => <StatCardBase highlight={highlight} flex={flex}>
  <h2>{stat}</h2>
  <h3>{title}</h3>
  <p>{description}</p>
</StatCardBase>

export default StatisticsCard;