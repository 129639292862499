import styled from 'styled-components';

const TabbedCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  & > h3, & > h4 {
    margin: 0.25rem 0;
  }

  & > :first-child {
    flex: 2;
  }
  & > :last-child {
    flex: 1;
  }
  & .mdc-tab__text-label {
    text-transform: none;
  }
  & .mdc-tab--active .mdc-tab__text-label {
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
  }
  & .mdc-tab__ripple.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0;
    --mdc-ripple-fg-size: 0!important;
  }
`;

export default TabbedCardHeader;