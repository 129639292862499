import React from "react";
import styled from "styled-components";

import { BigContainer } from "components/Containers";
import { H2 } from "components/Typography";

import InviteForm from "containers/InviteForm";
import TopBar from "components/TopBar";

const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;
  padding: 1em;
  padding-top: 0px;
`;

const AddPatient = () => {
  return (
    <>
      <TopBar title="Add Patient" />
      <Content>
        <BigContainer>
          <H2 subheader>New Profile</H2>
          <InviteForm />
        </BigContainer>
      </Content>
    </>
  );
};

export default AddPatient;
