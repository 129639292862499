import React from 'react';
import styled from 'styled-components';

import icon from "../../assets/emptyProfileIcon.svg";
import {H2, Body, Body2} from '../../components/Typography'
import {
  ProfileCardBase,
  StyledButton,
  CircleImage150px,
} from "../../GlobalStyles/GlobalStyles";
import { CenteredFlexDiv, RowDiv } from "../../components/Containers/";

export const BlockProfileCardBase = styled(ProfileCardBase)`
  display: inline-block;
`;

const ProfileCard = ({title, subTitle, email, ButtonTxt, imgUrl}) => {

  const  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const month = months[new Date(subTitle).getMonth()]
  const year = new Date(subTitle).getFullYear()

  return (
    <BlockProfileCardBase>
      <CenteredFlexDiv>
        <CircleImage150px
          style={{ alignSelf: "center" }}
          src={imgUrl ? imgUrl : icon}
          alt="Patient Icon"
        />
      </CenteredFlexDiv>

      <div>
        <H2>{title}</H2>
        <Body>
          Joined in {month} {year}
        </Body>
        {email ? (
          <RowDiv>
            <Body2>Email:</Body2>
            <Body>{email}</Body>
          </RowDiv>
        ) : null}
        <StyledButton to="/my-profile/edit" secondary>
          {ButtonTxt}
        </StyledButton>
      </div>
    </BlockProfileCardBase>
  );
} 

export default ProfileCard;