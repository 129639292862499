import React, { useState } from 'react';
import VideoPlayer from 'components/VideoPlayer';

const SFSequenceVideoPlayer = ({ autoPlay, sequence, activeIndex, setActiveIndex }) => {
  const [playing, setPlaying] = useState(!!autoPlay);

  const onCompleted = activeIndex => () => {
    let newIndex = (activeIndex + 1) % sequence.length;
    if (newIndex === 0) {
      setPlaying(false);
    }
    setActiveIndex(newIndex);
  }

  return <VideoPlayer 
    src={sequence[activeIndex]} 
    onCompleted={onCompleted(activeIndex)}
    playState={[playing, setPlaying]} />
};

export default SFSequenceVideoPlayer;